import React, { useEffect, useState } from 'react'
import {
  AppBar,
  Box,
  Button,
  Dialog ,
  Grid,
  IconButton,
  Slide,
  Typography,
  Toolbar,
} from '@mui/material'
import { Close } from '@mui/icons-material';
import { useAuth } from '../../context/authContext';
import { ButtonCust } from '../ui/Buttons';
import { gradientTwo } from '../../lib/Styles';
import MainWrapper from '../templates/MainWrapper'
import TooltipTemplate from '../ui/TooltipTemplate';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const UseTooltip = (props) => {
  const { children, tooltipInfo, tooltipBg } = props;

  return (
    tooltipInfo
      ?
        <TooltipTemplate
          info={tooltipInfo}
          background={tooltipBg}
        >
          {children}
        </TooltipTemplate>
      :
        <>        
          {children}
        </>
  )
}

const DialogTemplate = (props) => {
  const {
    button,
    colorBackground,
    colorTitle,
    buttonProps,
    buttonText,
    buttonGradient,
    content,
    bgGradient,
    icon,
    iconProps,
    onOpen,
    submitted,
    title,
    tooltipInfo,
    tooltipBg
  } = props;

  const authContext = useAuth();

  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
    if (onOpen) {
      onOpen();
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    setOpenDialog(false);
  }, [submitted])



  return (
    <>
      <UseTooltip
        tooltipInfo={tooltipInfo}
        tooltipBg={tooltipBg}
      >
        {icon ? 
          button ?
            <Button
              onClick={handleOpenDialog}
              {...iconProps}
              sx={{
                backgroundColor:'transparent',
                p:0,
                minWidth:0,
                borderRadius:20,
                '&&:hover':{
                  backgroundColor:'#00000010'
                },
                ...iconProps?.sx,
              }}
            >
              {icon}
            </Button>
          :
            <Box
              onClick={handleOpenDialog}
              sx={{
                display:'flex',
                cursor:'pointer'
              }}
            >
              {icon}
            </Box>
        :
          buttonGradient ? (
            <ButtonCust
              buttonProps={buttonProps}
              onClick={handleOpenDialog}
              type="submit"
              variant="contained"
              color1Start="primary.dark"
              color1End="primary.main"
              color2Start="primary.light"
              color2End="primary.main"
              angle="135deg"
              sx={{
                color:'#fff',
                // mt:2
              }}
            >
              {buttonText}
            </ButtonCust>

          ):(
            <Button {...buttonProps} onClick={handleOpenDialog}>{buttonText}</Button>
          )
        }
      </UseTooltip>

      <Dialog
        fullScreen
        open={openDialog}
        onClose={handleCloseDialog}
        TransitionComponent={Transition}
        disableEscapeKeyDown
        PaperProps={{
          sx: {
            // background: bgGradient && bgGradient,
            backgroundColor: colorBackground ? colorBackground : 'background.main'
          }
        }}
      >
        <AppBar
          sx={{
            position: 'fixed',
            background: bgGradient,
            backgroundColor:colorTitle ? colorTitle : '#fff'
          }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseDialog}
              aria-label="close"
            >
              <Close />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1, color:'#fff', fontSize:20, lineHeight:1 }} variant="h6" component="div">
              {title}
            </Typography>
            <Typography sx={{color:'#fff'}}>{`${authContext.account?.firstName} ${authContext.account?.lastName}`}</Typography>
            {/* <Button autoFocus color="inherit" onClick={handleCloseDialog}>
              save
            </Button> */}
          </Toolbar>
        </AppBar>
        <Grid
          container
          justifyContent="center"
          sx={{
            my:15
          }}
        >
          <MainWrapper>
            {content}
          </MainWrapper>
        </Grid>
      </Dialog>
    </>
  )
}

export default DialogTemplate
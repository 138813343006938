import React from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { Logo, Logotype } from '../../assets/logos'
import { gradientCustom } from '../../lib/Styles'
import { useTheme } from '@emotion/react'

const MainFooter = () => {
  const theme = useTheme();

  return (
    <>
      <Grid
        container
        sx={{
          // backgroundColor: 'green.dark',
          background:
          gradientCustom({
            theme:theme, 
            type1:'light',
            type2:' ',
            color1:'nine',
            color2:'eight',
            deg:'135deg',
            opacity:'f8'
          })
        ,
          height: '80px',
          justifyContent: 'center',
          alignItems: 'center',
          p:1,
          overflow: 'hidden',
          zIndex: 0
        }}
        xs={12}
      >
          <Grid container alignItems="center" justifyContent="center" display="flex">
            <Logo
              mt={7}
              height="30px"
              color1="#ffffffaa"
            />

            {/* <Logotype
              mt={7}
              height="30px"
              color="#fff" //{theme.palette.green.dark}
            /> */}
            <Typography variant="subtitle1" color="white" ml={3}>Copyright 2023 Opine</Typography>
          </Grid>
      </Grid>
    </>
  )
}

export default MainFooter

import React from 'react'

export const Logotype = (props) => {
  const { width, height, color, opacity, mt } = props;
  return (
    <div style={{marginTop:mt ? mt : 0}}>
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 334.9999 94.5"><title>OpineLogotype</title><path fill={color} fillOpacity={opacity} d="M36.5,0A36.5,36.5,0,1,0,73,36.5,36.5416,36.5416,0,0,0,36.5,0Zm0,54A17.5,17.5,0,1,1,54,36.5,17.52,17.52,0,0,1,36.5,54Z"/><path fill={color} fillOpacity={opacity} d="M116.1665,0A36.2751,36.2751,0,0,0,97.37,5.2251,9.4942,9.4942,0,0,0,79.6665,10V85a9.5,9.5,0,0,0,19,0V68.5212A36.4963,36.4963,0,1,0,116.1665,0Zm0,54a17.5,17.5,0,1,1,17.5-17.5A17.52,17.52,0,0,1,116.1665,54Z"/><path fill={color} fillOpacity={opacity} d="M220.62.01a35.62,35.62,0,0,0-17.8687,5.2931A9.4958,9.4958,0,0,0,185,10V63a9.5,9.5,0,0,0,19,0V36.5a17.5206,17.5206,0,0,1,17.2035-17.4975C230.9905,18.8391,239,27.1751,239,36.9635V63a9.5,9.5,0,0,0,19,0V36.5A36.54,36.54,0,0,0,220.62.01Z"/><path fill={color} fillOpacity={opacity} d="M167.5.5A9.5,9.5,0,0,0,158,10V63a9.5,9.5,0,0,0,19,0V10A9.5,9.5,0,0,0,167.5.5Z"/><path fill={color} fillOpacity={opacity} d="M296.6833.0444a36.5018,36.5018,0,1,0,21.2941,67.3588,9.5229,9.5229,0,0,0,.7019-15.6491h0a9.3634,9.3634,0,0,0-10.6963-.5309A17.5124,17.5124,0,1,1,313.1909,27H297a9.5,9.5,0,0,0,0,19h28.5a9.5343,9.5343,0,0,0,9.5-9.5969A36.54,36.54,0,0,0,296.6833.0444Z"/></svg>
    </div>
  )
}

export const Logomark = (props) => {
  let { width, height, color1, color2, color3, opacity1, opacity2, opacity3, mt } = props;
  color1 = color1 ? color1 : '#008071'
  color2 = color2 ? color2 : '#39b58a'
  color3 = color3 ? color3 : '#6fdd9b'
  opacity1 = opacity1 ? opacity1 : 1
  opacity2 = opacity2 ? opacity2 : 1
  opacity3 = opacity3 ? opacity3 : 1

  return (
    <div style={{marginTop:mt ? mt : 0}}>
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 73 73">
        <title>OpineLogomark</title>
        <path fill={color1} fillOpacity={opacity1} d="M73,36.5A36.5,36.5,0,1,0,36.5,73,36.5416,36.5416,0,0,0,73,36.5"/>
        <path fill={color2} fillOpacity={opacity2} d="M60.4622,30.2311A30.2311,30.2311,0,1,0,30.2311,60.4622,30.2655,30.2655,0,0,0,60.4622,30.2311"/>
        <path fill={color3} fillOpacity={opacity3} d="M38.47,19.2348A19.2348,19.2348,0,1,0,19.2348,38.47,19.2566,19.2566,0,0,0,38.47,19.2348"/>
      </svg>
    </div>
  )
}

export const OldLogo = (props) => {
  let { width, height, color1, color2, color3, color4, opacity1, opacity2, opacity3, opacity4, mt } = props;
  color1 = color1 ? color1 : '#008071'
  color2 = color2 ? color2 : '#39b58a'
  color3 = color3 ? color3 : '#6fdd9b'
  color4 = color4 ? color4 : '#008071'
  opacity1 = opacity1 ? opacity1 : 1
  opacity2 = opacity2 ? opacity2 : 1
  opacity3 = opacity3 ? opacity3 : 1
  opacity4 = opacity4 ? opacity4 : 1

  return (
    <div style={{marginTop:mt ? mt : 0}}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 466.4087 112.2391"
      >
        <title>OpineLogo</title>
        <path fill={color4} fillOpacity={opacity4} d="M167.9088,12.2391a36.5,36.5,0,1,0,36.5,36.5A36.5416,36.5416,0,0,0,167.9088,12.2391Zm0,54a17.5,17.5,0,1,1,17.5-17.5A17.52,17.52,0,0,1,167.9088,66.2391Z"/>
        <path fill={color4} fillOpacity={opacity4} d="M247.5753,12.2391a36.2751,36.2751,0,0,0-18.7964,5.2251,9.4942,9.4942,0,0,0-17.7036,4.7749v80.5a9.5,9.5,0,0,0,19,0V80.76a36.4963,36.4963,0,1,0,17.5-68.5212Zm0,54a17.5,17.5,0,1,1,17.5-17.5A17.52,17.52,0,0,1,247.5753,66.2391Z"/>
        <path fill={color4} fillOpacity={opacity4} d="M352.0287,12.25A35.62,35.62,0,0,0,334.16,17.5426a9.4958,9.4958,0,0,0-17.7512,4.6965v53a9.5,9.5,0,1,0,19,0v-26.5a17.5207,17.5207,0,0,1,17.2035-17.4976c9.787-.1634,17.7965,8.1726,17.7965,17.9611V75.2391a9.5,9.5,0,1,0,19,0v-26.5A36.54,36.54,0,0,0,352.0287,12.25Z"/>
        <path fill={color4} fillOpacity={opacity4} d="M298.9088,12.7391a9.5,9.5,0,0,0-9.5,9.5v53a9.5,9.5,0,1,0,19,0v-53A9.5,9.5,0,0,0,298.9088,12.7391Z"/>
        <path fill={color4} fillOpacity={opacity4} d="M428.0922,12.2834a36.5019,36.5019,0,1,0,21.294,67.3589,9.5229,9.5229,0,0,0,.7019-15.6492h0a9.3634,9.3634,0,0,0-10.6963-.5308A17.5124,17.5124,0,1,1,444.6,39.2391H428.4088a9.5,9.5,0,0,0,0,19h28.5a9.5343,9.5343,0,0,0,9.5-9.597A36.54,36.54,0,0,0,428.0922,12.2834Z"/>
        <path fill={color1} fillOpacity={opacity1} d="M97.4782,48.7391A48.7391,48.7391,0,1,0,48.7391,97.4782,48.7946,48.7946,0,0,0,97.4782,48.7391"/>
        <path fill={color2} fillOpacity={opacity2} d="M80.7363,40.3681A40.3682,40.3682,0,1,0,40.3681,80.7363,40.4142,40.4142,0,0,0,80.7363,40.3681"/>
        <path fill={color3} fillOpacity={opacity3} d="M51.3691,25.6845A25.6846,25.6846,0,1,0,25.6845,51.3691,25.7138,25.7138,0,0,0,51.3691,25.6845"/>
      </svg>
    </div>
  )
}

export const Logo = (props) => {
  let { width, height, color1, color2, color3, color4, opacity1, opacity2, opacity3, opacity4, mt } = props;
  color1 = color1 ? color1 : '#008071'
  color2 = color2 ? color2 : color1
  color3 = color3 ? color3 : color1
  color4 = color4 ? color4 : color1
  opacity1 = opacity1 ? opacity1 : 1
  opacity2 = opacity2 ? opacity2 : 1
  opacity3 = opacity3 ? opacity3 : 1

  return (
    <div style={{marginTop:mt ? mt : 0}}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 466.4091 112.2392"
      >
        <title>OpineLogo</title>
        <path fill={color1} opacity={opacity1} d="M167.9089,12.2392a36.5,36.5,0,1,0,36.5,36.5A36.5,36.5,0,0,0,167.9089,12.2392Zm0,54a17.5,17.5,0,1,1,17.5-17.5A17.5,17.5,0,0,1,167.9089,66.2392Z"/>
        <path fill={color1} opacity={opacity1} d="M247.5754,12.2392a36.2758,36.2758,0,0,0-18.7964,5.2251,9.4942,9.4942,0,0,0-17.7036,4.7749v80.5a9.5,9.5,0,0,0,19,0V80.76a36.4963,36.4963,0,1,0,17.5-68.5212Zm0,54a17.5,17.5,0,1,1,17.5-17.5A17.5,17.5,0,0,1,247.5754,66.2392Z"/>
        <path fill={color1} opacity={opacity1} d="M352.0288,12.25A35.62,35.62,0,0,0,334.16,17.5427a9.4958,9.4958,0,0,0-17.7512,4.6965v53a9.5,9.5,0,0,0,19,0v-26.5a17.5207,17.5207,0,0,1,17.2035-17.4976c9.787-.1634,17.7965,8.1726,17.7965,17.9611V75.2392a9.5,9.5,0,0,0,19,0h0v-26.5a36.54,36.54,0,0,0-36.5812-36.4987Q352.4283,12.241,352.0288,12.25Z"/>
        <path fill={color1} opacity={opacity1} d="M298.9089,12.7392a9.5,9.5,0,0,0-9.5,9.5h0v53a9.5,9.5,0,0,0,19,0v-53a9.5,9.5,0,0,0-9.5-9.5Z"/>
        <path fill={color1} opacity={opacity1} d="M428.0923,12.2835a36.5019,36.5019,0,1,0,21.294,67.3589,9.5229,9.5229,0,0,0,.7019-15.6492h0a9.3634,9.3634,0,0,0-10.6963-.5308A17.5124,17.5124,0,1,1,444.6,39.2392H428.4089a9.5,9.5,0,0,0,0,19h28.5a9.5343,9.5343,0,0,0,9.5-9.5683l0-.0287a36.54,36.54,0,0,0-36.6778-36.4017Q428.9114,12.2436,428.0923,12.2835Z"/>
        <path fill={color2} opacity={opacity2} d="M48.7392,0c-1.46,0-2.9013.0762-4.3285.2019A40.3687,40.3687,0,1,1,.2019,44.4107C.0763,45.8379,0,47.2793,0,48.7392A48.7391,48.7391,0,1,0,48.7392,0Z"/>
        <path fill={color3} opacity={opacity3} d="M51.3692,25.6846A25.6846,25.6846,0,1,0,25.6846,51.3692h0A25.7139,25.7139,0,0,0,51.3692,25.6846"/>
      </svg>
    </div>
  )
}

import React, { useState } from 'react'
import { Grid, Typography } from '@mui/material';
import { Logo } from '../assets/logos';
import { useTheme } from '@emotion/react';

const images = [
  'url(https://images.unsplash.com/photo-1522736342783-e91dcbe83b9e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2091&q=80)',
  'url(https://images.unsplash.com/photo-1510133768164-a8f7e4d4e3dc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1171&q=80)',
  'url(https://images.unsplash.com/photo-1489619547086-641e1c87c3ff?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1136&q=80)',
  'url(https://images.unsplash.com/photo-1590642956346-d2c9095f0bf1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=763&q=80)',
  'url(https://images.unsplash.com/photo-1508138221679-760a23a2285b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80)',
  'url(https://images.unsplash.com/photo-1590642916589-592bca10dfbf?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=763&q=80)',
  'url(https://images.unsplash.com/photo-1504701954957-2010ec3bcec1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1974&q=80)',
  'url(https://images.unsplash.com/photo-1608752662177-14a5a835b442?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80)'
]

const NotFound = () => {
  const theme = useTheme();

  return (
    <>
      <Grid
        container
        sx={{
          // backgroundImage:images[Math.floor(Math.random()*images.length)],
          objectFit:'cover',
          justifyContent:'center',
          height:'100vh',
          alignItems:'center',
          mt:8
        }}
      >
        <Grid
          item
          container
          sx={{
            justifyContent:'center',
            // backgroundColor: '#eeffff',
            height:'100%',
            borderRadius: 3,
            p: 2
          }}
          sm={12}
          md={6}
        >
          <Grid
            container
            sx={{
              alignItems:'flex-start',
              justifyContent:'center'
            }}
            sm={12}
            md={2}
          >
            <Grid item mr={2}>
              <Logo color={theme.palette.primary.main} width={50} />
            </Grid>
          </Grid>
          <Grid
            item
            xs={11}
            md={6}
          >
            <Typography variant="h2">NOT FOUND</Typography>
            <Typography variant="h4" sx={{mt:2, lineHeight:1.2, textTransform:'none'}}>Whoa there...it looks like you're either trying to get somewhere you don't belong or perhaps you just got lost. Either Way, you straight up 404'd</Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default NotFound

export const fieldFormat = {
  standard: {
    borderWidth:0,
    '& fieldset':{
      borderRadius:2,
      borderWidth:0,
      borderStyle:'none'
    }
  },
  comments: {
    backgroundColor: '#ffffff55'
  },
  company: {
    backgroundColor: '#ddeeff55',
    mb: 2
  }
}

export const Gradient1 = (props) => {
  const {
    angle,
    startColor,
    midColor,
    endColor,
    opacity
  } = props;
  const deg = angle ? angle : '-45deg';
  const color1 = startColor ? (`${startColor}${opacity ? opacity : '20'}`) : ('#FF737320')
  const color2 = midColor ? (`${midColor}${opacity ? opacity : '20'}`) : ('#FFAD6620')
  const color3 = endColor ? (`${endColor}${opacity ? opacity : '20'}`) : ('#6BD9FF20')

  // return `linear-gradient(${deg}, ${startColor && color1}, ${midColor && color2}, ${endColor && color3})`
  return 'linear-gradient(-45deg, #B4FF7A40, #5CFF9B40, #6BD9FF40)'
}

export const gradientCustom = (obj) => {
  const { theme, type1, type2, opacity, color1, color2, deg } = obj;
  const opacityIn = opacity ? opacity : 'ff'
  const degIn = deg ? deg : '-45deg'
  const brandIn1 = () => {
    switch (type1) {
      case 'pastel':
        return 'brandPastel'
      case 'light':
        return 'brandLight'    
      case 'dark':
        return 'brandDark'    
      default:
        return 'brand'
    }
  }
  const brandIn2 = () => {
    switch (type2) {
      case 'pastel':
        return 'brandPastel'
      case 'light':
        return 'brandLight'    
      case 'dark':
        return 'brandDark'    
      default:
        return 'brand'
    }
  }
  
  return `linear-gradient(${degIn}, ${theme.palette[type1 ? brandIn1() : brandIn2()][color1]}${opacityIn}, ${theme.palette[type2 ? brandIn2() : brandIn1()][color2]}${opacityIn})`
  
  // switch (type) {
  //   case 'pastel':
  //     return `linear-gradient(${degIn}, ${theme.palette.brandPastel[color1]}${opacityIn}, ${theme.palette.brandPastel[color2]}${opacityIn})`
  
  //   case 'light':
  //     return `linear-gradient(${degIn}, ${theme.palette.brandLight[color1]}${opacityIn}, ${theme.palette.brandLight[color2]}${opacityIn})`
  
  //   case 'dark':
  //     return `linear-gradient(${degIn}, ${theme.palette.brandDark[color1]}${opacityIn}, ${theme.palette.brandDark[color2]}${opacityIn})`
  
  //   default:
  //     return `linear-gradient(${degIn}, ${theme.palette.brand[color1]}${opacityIn}, ${theme.palette.brand[color2]}${opacityIn})`
  // }
}

export const gradientContent = (obj) => {
  const { theme, type1, type2, opacity, color1, color2, deg } = obj;
  const opacityIn = opacity ? opacity : 'ff'
  const degIn = deg ? deg : '-45deg'
  
  return `linear-gradient(${degIn}, ${theme.palette[color1 ? color1 : color2][type1 ? type1 : type2]}${opacityIn}, ${theme.palette[color2 ? color2 : color1][type2 ? type2 : type1]}${opacityIn})`
  }

export const gradientOne = (theme, type, opacity) => {
  const typeIn = type ? type : ''
  const opacityIn = opacity ? opacity : 'ff'

  switch (type) {
    case 'pastel':
      return `linear-gradient(-45deg, ${theme.palette.brandPastel.one}${opacityIn}, ${theme.palette.brandPastel.two}${opacityIn})`
  
    case 'light':
      return `linear-gradient(-45deg, ${theme.palette.brandLight.one}${opacityIn}, ${theme.palette.brandLight.two}${opacityIn})`
  
    case 'dark':
      return `linear-gradient(-45deg, ${theme.palette.brandDark.one}${opacityIn}, ${theme.palette.brandDark.two}${opacityIn})`
  
    default:
      return `linear-gradient(-45deg, ${theme.palette.brand.one}${opacityIn}, ${theme.palette.brand.two}${opacityIn})`
  }
}

export const gradientTwo = (theme, type, opacity) => {
  const typeIn = type ? type : ''
  const opacityIn = opacity ? opacity : 'ff'

  switch (type) {
    case 'pastel':
      return `linear-gradient(-45deg, ${theme.palette.brandPastel.two}${opacityIn}, ${theme.palette.brandPastel.three}${opacityIn})`
  
    case 'light':
      return `linear-gradient(-45deg, ${theme.palette.brandLight.two}${opacityIn}, ${theme.palette.brandLight.three}${opacityIn})`
  
    case 'dark':
      return `linear-gradient(-45deg, ${theme.palette.brandDark.two}${opacityIn}, ${theme.palette.brandDark.three}${opacityIn})`
  
    default:
      return `linear-gradient(-45deg, ${theme.palette.brand.two}${opacityIn}, ${theme.palette.brand.three}${opacityIn})`
  }
}

export const gradientThree = (theme, type, opacity) => {
  const typeIn = type ? type : ''
  const opacityIn = opacity ? opacity : 'ff'

  switch (type) {
    case 'pastel':
      return `linear-gradient(-45deg, ${theme.palette.brandPastel.three}${opacityIn}, ${theme.palette.brandPastel.four}${opacityIn})`
  
    case 'light':
      return `linear-gradient(-45deg, ${theme.palette.brandLight.three}${opacityIn}, ${theme.palette.brandLight.four}${opacityIn})`
  
    case 'dark':
      return `linear-gradient(-45deg, ${theme.palette.brandDark.three}${opacityIn}, ${theme.palette.brandDark.four}${opacityIn})`
  
    default:
      return `linear-gradient(-45deg, ${theme.palette.brand.three}${opacityIn}, ${theme.palette.brand.four}${opacityIn})`
  }
}

export const gradientFour = (theme, type, opacity) => {
  const typeIn = type ? type : ''
  const opacityIn = opacity ? opacity : 'ff'

  switch (type) {
    case 'pastel':
      return `linear-gradient(-45deg, ${theme.palette.brandPastel.four}${opacityIn}, ${theme.palette.brandPastel.five}${opacityIn})`
  
    case 'light':
      return `linear-gradient(-45deg, ${theme.palette.brandLight.four}${opacityIn}, ${theme.palette.brandLight.five}${opacityIn})`
  
    case 'dark':
      return `linear-gradient(-45deg, ${theme.palette.brandDark.four}${opacityIn}, ${theme.palette.brandDark.five}${opacityIn})`
  
    default:
      return `linear-gradient(-45deg, ${theme.palette.brand.four}${opacityIn}, ${theme.palette.brand.five}${opacityIn})`
  }
}

export const gradientFive = (theme, type, opacity) => {
  const typeIn = type ? type : ''
  const opacityIn = opacity ? opacity : 'ff'

  switch (type) {
    case 'pastel':
      return `linear-gradient(-45deg, ${theme.palette.brandPastel.five}${opacityIn}, ${theme.palette.brandPastel.six}${opacityIn})`
  
    case 'light':
      return `linear-gradient(-45deg, ${theme.palette.brandLight.five}${opacityIn}, ${theme.palette.brandLight.six}${opacityIn})`
  
    case 'dark':
      return `linear-gradient(-45deg, ${theme.palette.brandDark.five}${opacityIn}, ${theme.palette.brandDark.six}${opacityIn})`
  
    default:
      return `linear-gradient(-45deg, ${theme.palette.brand.five}${opacityIn}, ${theme.palette.brand.six}${opacityIn})`
  }
}

export const gradientSix = (theme, type, opacity) => {
  const typeIn = type ? type : ''
  const opacityIn = opacity ? opacity : 'ff'

  switch (type) {
    case 'pastel':
      return `linear-gradient(-45deg, ${theme.palette.brandPastel.six}${opacityIn}, ${theme.palette.brandPastel.seven}${opacityIn})`
  
    case 'light':
      return `linear-gradient(-45deg, ${theme.palette.brandLight.six}${opacityIn}, ${theme.palette.brandLight.seven}${opacityIn})`

    case 'dark':
      return `linear-gradient(-45deg, ${theme.palette.brandDark.six}${opacityIn}, ${theme.palette.brandDark.seven}${opacityIn})`
  
    default:
      return `linear-gradient(-45deg, ${theme.palette.brand.six}${opacityIn}, ${theme.palette.brand.seven}${opacityIn})`
  }
}

export const gradientSeven = (theme, type, opacity) => {
  const typeIn = type ? type : ''
  const opacityIn = opacity ? opacity : 'ff'

  switch (type) {
    case 'pastel':
      return `linear-gradient(-45deg, ${theme.palette.brandPastel.seven}${opacityIn}, ${theme.palette.brandPastel.eight}${opacityIn})`
  
    case 'light':
      return `linear-gradient(-45deg, ${theme.palette.brandLight.seven}${opacityIn}, ${theme.palette.brandLight.eight}${opacityIn})`

    case 'dark':
      return `linear-gradient(-45deg, ${theme.palette.brandDark.seven}${opacityIn}, ${theme.palette.brandDark.eight}${opacityIn})`
  
    default:
      return `linear-gradient(-45deg, ${theme.palette.brand.seven}${opacityIn}, ${theme.palette.brand.eight}${opacityIn})`
  }
}

export const gradientEight = (theme, type, opacity) => {
  const typeIn = type ? type : ''
  const opacityIn = opacity ? opacity : 'ff'

  switch (type) {
    case 'pastel':
      return `linear-gradient(-45deg, ${theme.palette.brandPastel.eight}${opacityIn}, ${theme.palette.brandPastel.nine}${opacityIn})`
  
    case 'light':
      return `linear-gradient(-45deg, ${theme.palette.brandLight.eight}${opacityIn}, ${theme.palette.brandLight.nine}${opacityIn})`

    case 'dark':
      return `linear-gradient(-45deg, ${theme.palette.brandDark.eight}${opacityIn}, ${theme.palette.brandDark.nine}${opacityIn})`
  
    default:
      return `linear-gradient(-45deg, ${theme.palette.brand.eight}${opacityIn}, ${theme.palette.brand.nine}${opacityIn})`
  }
}

export const gradientNine = (theme, type, opacity) => {
  const typeIn = type ? type : ''
  const opacityIn = opacity ? opacity : 'ff'

  switch (type) {
    case 'pastel':
      return `linear-gradient(-45deg, ${theme.palette.brandPastel.nine}${opacityIn}, ${theme.palette.brandPastel.ten}${opacityIn})`
  
    case 'light':
      return `linear-gradient(-45deg, ${theme.palette.brandLight.nine}${opacityIn}, ${theme.palette.brandLight.ten}${opacityIn})`

    case 'dark':
      return `linear-gradient(-45deg, ${theme.palette.brandDark.nine}${opacityIn}, ${theme.palette.brandDark.ten}${opacityIn})`
  
    default:
      return `linear-gradient(-45deg, ${theme.palette.brand.nine}${opacityIn}, ${theme.palette.brand.ten}${opacityIn})`
  }
}

export const gradientTen = (theme, type, opacity) => {
  const typeIn = type ? type : ''
  const opacityIn = opacity ? opacity : 'ff'

  switch (type) {
    case 'pastel':
      return `linear-gradient(-45deg, ${theme.palette.brandPastel.ten}${opacityIn}, ${theme.palette.brandPastel.one}${opacityIn})`
  
    case 'light':
      return `linear-gradient(-45deg, ${theme.palette.brandLight.ten}${opacityIn}, ${theme.palette.brandLight.one}${opacityIn})`

    case 'dark':
      return `linear-gradient(-45deg, ${theme.palette.brandDark.ten}${opacityIn}, ${theme.palette.brandDark.one}${opacityIn})`
  
    default:
      return `linear-gradient(-45deg, ${theme.palette.brand.ten}${opacityIn}, ${theme.palette.brand.one}${opacityIn})`
  }
}

export const gradientTenTwo= (theme, type, opacity) => {
  const typeIn = type ? type : ''
  const opacityIn = opacity ? opacity : 'ff'

  switch (type) {
    case 'pastel':
      return `linear-gradient(-45deg, ${theme.palette.brandPastel.ten}${opacityIn}, ${theme.palette.brandPastel.two}${opacityIn})`
  
    case 'light':
      return `linear-gradient(-45deg, ${theme.palette.brandLight.ten}${opacityIn}, ${theme.palette.brandLight.two}${opacityIn})`

    case 'dark':
      return `linear-gradient(-45deg, ${theme.palette.brandDark.ten}${opacityIn}, ${theme.palette.brandDark.two}${opacityIn})`
  
    default:
      return `linear-gradient(-45deg, ${theme.palette.brand.ten}${opacityIn}, ${theme.palette.brand.two}${opacityIn})`
  }
}

export const gradientOneThree= (theme, type, opacity) => {
  const typeIn = type ? type : ''
  const opacityIn = opacity ? opacity : 'ff'

  switch (type) {
    case 'pastel':
      return `linear-gradient(-45deg, ${theme.palette.brandPastel.one}${opacityIn}, ${theme.palette.brandPastel.three}${opacityIn})`
  
    case 'light':
      return `linear-gradient(-45deg, ${theme.palette.brandLight.one}${opacityIn}, ${theme.palette.brandLight.three}${opacityIn})`

    case 'dark':
      return `linear-gradient(-45deg, ${theme.palette.brandDark.one}${opacityIn}, ${theme.palette.brandDark.three}${opacityIn})`
  
    default:
      return `linear-gradient(-45deg, ${theme.palette.brand.one}${opacityIn}, ${theme.palette.brand.three}${opacityIn})`
  }
}

export const gradientTwoFour= (theme, type, opacity) => {
  const typeIn = type ? type : ''
  const opacityIn = opacity ? opacity : 'ff'

  switch (type) {
    case 'pastel':
      return `linear-gradient(-45deg, ${theme.palette.brandPastel.two}${opacityIn}, ${theme.palette.brandPastel.four}${opacityIn})`
  
    case 'light':
      return `linear-gradient(-45deg, ${theme.palette.brandLight.two}${opacityIn}, ${theme.palette.brandLight.four}${opacityIn})`

    case 'dark':
      return `linear-gradient(-45deg, ${theme.palette.brandDark.two}${opacityIn}, ${theme.palette.brandDark.four}${opacityIn})`
  
    default:
      return `linear-gradient(-45deg, ${theme.palette.brand.two}${opacityIn}, ${theme.palette.brand.four}${opacityIn})`
  }
}

export const gradientThreeFive= (theme, type, opacity) => {
  const typeIn = type ? type : ''
  const opacityIn = opacity ? opacity : 'ff'

  switch (type) {
    case 'pastel':
      return `linear-gradient(-45deg, ${theme.palette.brandPastel.three}${opacityIn}, ${theme.palette.brandPastel.five}${opacityIn})`
  
    case 'light':
      return `linear-gradient(-45deg, ${theme.palette.brandLight.three}${opacityIn}, ${theme.palette.brandLight.five}${opacityIn})`

    case 'dark':
      return `linear-gradient(-45deg, ${theme.palette.brandDark.three}${opacityIn}, ${theme.palette.brandDark.five}${opacityIn})`
  
    default:
      return `linear-gradient(-45deg, ${theme.palette.brand.three}${opacityIn}, ${theme.palette.brand.five}${opacityIn})`
  }
}

import React, { useState } from 'react'
import { features } from '../../lib/ProductLib'
import { Button, Divider, Grid, Typography } from '@mui/material'
import { ButtonCust } from '../ui/Buttons';

const FeatureList = (props) => {
  const { handleClickOpen } = props;

  const [selectedFeature, setSelectedFeature] = useState(null);

  return (
    <>
      <Grid
        container
        id="features"
        sx={{
          pt:6
        }}
      >
        <Grid
          container
        >
          <Divider sx={{width:'100%', my:4}}>
            <Typography
              variant="h3"
              sx={{
                lineHeight:0
                // mt:-4
              }}
            >
              Features
            </Typography>
          </Divider>
            
        </Grid>
        <Grid
          container
          sx={{
            alignItems:'stretch'
          }}
        >
          {
            features.map((feature, index) => (
              <FeatureItem
                onClick={() => setSelectedFeature(feature.label)}
                selectedFeature={selectedFeature}
                key={feature.label}
                feature={feature}
                index={index}
              />
            ))
          }

          <Grid
            item
            xs
          >
            <Grid
              container
              sx={{
                m:2,
                p:2,
                width:'calc(100% - 32px)',
                height:'calc(100% - 32px)',
                borderRadius:3,
                boxShadow:'0 0 10px #00000030',
                justifyContent:'center',
                flexDisplay:'column',
                alignItems:'center',
                background:'#fcfcfc'
              }}
            >
              <Grid
                sx={{
                  flexDisplay:'center',
                  justifyContent:'center',
                  alignItems:'center'
                }}
              >
                <Typography
                  variant="h4"
                  textAlign="center"
                >
                  Find out more
                </Typography>

                <Grid
                  sx={{
                    textAlign:'center',
                    mb:2
                  }}
                >
                  <ButtonCust
                    onClick={() => handleClickOpen('register')}
                    sx={{
                      mt:{xs:2, md:4},
                      fontSize:{
                        xs:22, md:'1.5vw'
                      },
                      fontWeight:{
                        xs:600,
                        md:500
                      },
                      padding:{
                        xs:'14px 20px 14px 20px',
                        md:'20px 40px 20px 40px'
                      }
                    }}
                    buttonProps={{
                    }}
                    variant="contained"
                    color1Start="brand.nine"
                    color1End="brand.ten"
                    color2Start="brand.five"
                    color2End="brand.six"
                    angle="135deg"
                  >
                    Get Early Access
                  </ButtonCust>
                </Grid>
                <a
                  href="mailto:contact@opineschool.com"
                  style={{
                    textDecoration:'none'
                  }}
                >
                  <Typography
                    sx={{
                      textDecoration:'none',
                      width:'100%',
                      textAlign:'center',
                    }}
                  >
                    Or email us at contact@opineschool.com
                  </Typography>
                </a>


              </Grid>
            </Grid>
          </Grid>
        </Grid>

          
      </Grid>
        
    </>
  )
}

export default FeatureList

const FeatureItem = (props) => {
  const { feature, index, onClick, selectedFeature } = props;

  return (
    <Grid
      item
      xs={11}
      md={6}
      xl={4}
    >
      <Grid
        onClick={onClick}
        sx={{
          m:2,
          p:2,
          height:'calc(100% - 32px)',
          borderRadius:3,
          boxShadow:'0 0 10px #00000030',
          background:'#fcfcfc'
        }}
      >
        {
          feature.img &&
            <img
              src={feature.img}
              loading="lazy"
              style={{
                width:'100%',
                height:200,
                objectFit:'cover',
                objectPosition:'center',
                borderRadius:8,
                marginBottom:8
              }}
            />
        }
        <Typography
          variant="h4"
        >
          {feature.label}
        </Typography>
        <Typography
          sx={{
            lineHeight:1.3
          }}
        >
          {feature.description}
        </Typography>
      </Grid>
    </Grid>
  )
}
import React, { useEffect, useState } from 'react'
import { Close, InfoOutlined } from '@mui/icons-material'; 
import { useTheme } from '@emotion/react';
import {
  Typography,
  useMediaQuery,
  Tooltip
} from '@mui/material';
import { useAuth } from '../../context/authContext';

const TooltipTemplate = (props) => {
  const {
    arrow,
    arrowColor,
    background,
    backgroundColor,
    children,
    disableInteractive,
    disableFocusListener,
    setWidth,
    submitted,
    dialog,
    info,
    placement
    // infoDetails - Create additional section for further info details?
  } = props;

  const authContext = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {defaultMatches: true})

  const [maxWidth, setMaxWidth] = useState(setWidth ? setWidth : 'md');
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    setOpenDialog(false);
  }, [submitted])

  const handleClick = () => {
    if (dialog) {
      handleOpenDialog();
    }
    // if (onClick) {
    //   onClick();
    // }
  }

  return (
    <>
      <Tooltip
        disableInteractive={disableInteractive ? disableInteractive : false}
        disableFocusListener={disableFocusListener}
        title={
          <Typography
            sx={{
              color:'#fff',
              fontSize:16,
              lineHeight:1
            }}
          >
            {info}
          </Typography>
        }
        placement={placement ? placement : 'top'}
        arrow={arrow ? arrow : false}
        PopperProps={{
          sx: {
            "& .MuiTooltip-tooltip": {
              background: background ? background : theme.palette.primary.main,
              backgroundColor: backgroundColor ? backgroundColor : null,
              // border:`1px solid ${arrowColor}`,
              px:3,
              py:1
            },
            "& .MuiTooltip-arrow": {
              color: arrowColor ? arrowColor : theme.palette.primary.main,
            }
          }
        }}
      >
        <div style={{display:'inline'}}>
          {children}
        </div>
      </Tooltip>


    </>
  )
}

export default TooltipTemplate;
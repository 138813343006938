import { useEffect, useState } from 'react';
import { Route, Routes, useRoutes } from 'react-router';
import { useLocation } from 'react-router-dom'
import { Box, Button, CircularProgress, Grid, responsiveFontSizes, TextField, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { guestRoutes, studentRoutes, teacherRoutes } from './routes'
import { theme, themeLight } from './theme';
import { useAuth } from './context/authContext';
import NotFoundPage from './pages/NotFoundPage';
import { doc, getDoc } from 'firebase/firestore';
import { db } from './firebase';
import DialogTemplate from './components/dialogs/DialogTemplate';
import Feedback from './components/feedback/Feedback';
import LandingPage from './pages/LandingPage'
import AlertsTemplate from './components/alerts/AlertsTemplate';
import { Logomark } from './assets/logos';
// import Feedback from './components/ui/Feedback';

const dTheme = responsiveFontSizes(theme);

function App() {
  const authContext = useAuth();

  const studentContent = useRoutes(studentRoutes);
  const teacherContent = useRoutes(teacherRoutes);
  const guestContent = useRoutes(guestRoutes);
    
  const location = useLocation();
  const [isMessages, setIsMessages] = useState();

  const locationCheck = () => {
    if (location.pathname.substring(0,10) == '/') {
      setIsMessages(true);
    } else {
      setIsMessages(false);
    }
  }

  useEffect(() => {
    locationCheck();
  }, [location])

  //Alerts
  useEffect(() => {
    if (authContext.alert) {
      handleNotification();
    }
  }, [authContext.alert])

  const [goTime, setGoTime] = useState(false);
  const [notifyContent, setNotifyContent] = useState();
  const handleNotification = () => {
    setNotifyContent(authContext.alert);
    setGoTime(true);
    setTimeout(() => {setGoTime(false)}, 5000);
  };
  const handleStop = () => {
    setGoTime(false);
  };


  return (
    <>
      <AlertsTemplate
        content={notifyContent}
        color="#9B30F9"
        goTime={goTime}
        setGoTime={setGoTime}
        handleNotification={handleNotification}
        handleStop={handleStop}
      />

      {authContext.user ? (  // <-- Switched .user to .account to avoid hanging if indexedDB account store is deleted. This may cause issues if user account is reloaded. I think that is why .user was used (?)
        <ThemeProvider theme={themeLight}>
          {/* Notifications would go here */}
          {authContext.isTeacher
                ?
                  teacherContent
                : 
                  authContext.account?.type === 'student'
                    ?
                      studentContent
                    :
                      <Grid container height="calc(100vh - 96px)" justifyContent="center" alignItems="center">
                        <Box
                          sx={{
                            position:'relative',
                            display:'flex',
                            justifyContent:'center',
                            alignItems:'center'
                          }}
                        >
                          <CircularProgress
                            sx={{
                              color:theme.palette.brand.ten
                            }}
                            size={160}
                            thickness={4}
                          />
                          <Box
                            sx={{
                              position:'absolute'
                            }}
                          >
                            <Logomark
                              mt={7}
                              height={90}
                              color1={theme.palette.brand.eight}
                              color2="#fff" //{theme.palette.brandLight.nine}
                              color3={theme.palette.brand.eight}
                              color4={theme.palette.brand.eight}
                            />
                          </Box>
                        </Box>
                      </Grid>
          }
        </ThemeProvider>
      ) : (
        <ThemeProvider theme={themeLight}>
          {/* Notifications would go here */}
          {guestContent}
        </ThemeProvider>
      )}



    </>
  );
}

export default App;

import { Article, AttachMoney, Campaign, CheckCircle, ReportGmailerrorred } from "@mui/icons-material"


export const assignmentInfo = {
  rubric: [
    {label: 'Select Grading Type', value: null},
    {label: 'Complete/Incomplete', value: 'complete'},
    {label: 'Percentage', value: 'percentage'},
    {label: 'Points', value: 'points'},
    {label: 'Not Graded', value: 'none'}
  ]
}

export const feedbackInfo = {
  feedbackType: [
    {label: 'Select Feedback Type', value: null},
    {label: 'Bug', value: 'bug'},
    {label: 'Feature Request', value: 'featureRequest'},
    {label: 'General Feedback', value: 'general'}
  ]
}

export const infoMessages = {
  classes: {
    info:'',
    teacher:'All of the classes that you have created are here',
    student:'All of the classes that you have enrolled in are here'
  }
}
import { doc, getDoc } from 'firebase/firestore';
import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import LoadingScreen from './components/LoadingScreen';
import { useAuth } from './context/authContext';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

const HomePage = Loadable(lazy(() => import('./pages/HomePage')));
const HomeTeacherPage = Loadable(lazy(() => import('./pages/HomeTeacherPage')));
const LandingPage = Loadable(lazy(() => import('./pages/LandingPage')));
const NotFoundPage = Loadable(lazy(() => import('./pages/NotFoundPage')));
const SettingsPage = Loadable(lazy(() => import('./pages/SettingsPage')));
const FeedbackPage = Loadable(lazy(() => import('./pages/FeedbackPage')));
const MessagesPage = Loadable(lazy(() => import('./pages/MessagesPage')));
const MeetingsPage = Loadable(lazy(() => import('./pages/MeetingsPage')));

//DASHBOARD
const DashboardTeachersPage = Loadable(lazy(() => import('./pages/DashboardTeachersPage')));
const DashboardStudentsPage = Loadable(lazy(() => import('./pages/DashboardStudentsPage')));

//DISPLAY CONTENT
const OpinionPage = Loadable(lazy(() => import('./pages/OpinionPage')));
const OpinionCreatePage = Loadable(lazy(() => import('./pages/OpinionCreatePage')));
const ResponsePage = Loadable(lazy(() => import('./pages/ResponsePage')));
const ResponseCreatePage = Loadable(lazy(() => import('./pages/ResponseCreatePage')));
const ReplyPage = Loadable(lazy(() => import('./pages/ReplyPage')));
const ReplyCreatePage = Loadable(lazy(() => import('./pages/ReplyCreatePage')));

//AUTH
const LoginPage = Loadable(lazy(() => import('./pages/LoginPage')));
const RegisterPage = Loadable(lazy(() => import('./pages/RegisterPage')));

//TEST
const AvatarGeneratorAlt = Loadable(lazy(() => import('./components/avatar/AvatarGeneratorAlt')));
const AvatarGenerator = Loadable(lazy(() => import('./components/avatar/AvatarGenerator')));
const EmailSignaturePage = Loadable(lazy(() => import('./pages/EmailSignaturePage')));

//COURSES (CLASSES)
const CoursePage = Loadable(lazy(() => import('./pages/CoursePage')));
const CoursesPage = Loadable(lazy(() => import('./pages/CoursesPage')));
const AssignmentPage = Loadable(lazy(() => import('./pages/AssignmentPage')));
const AssignmentsPage = Loadable(lazy(() => import('./pages/AssignmentsPage')));
const AssignmentUserGeneratorPage = Loadable(lazy(() => import('./pages/AssignmentUserGeneratorPage')));

//TEACHER
const GradingPage = Loadable(lazy(() => import('./pages/GradingPage')));
const SplitGradingPage = Loadable(lazy(() => import('./pages/SplitGradingPage')));
const SplitReviewPage = Loadable(lazy(() => import('./pages/SplitReviewPage')));
const RegisterTeacherPage = Loadable(lazy(() => import('./pages/RegisterTeacherPage')));

//STUDENTS
const GradesPage = Loadable(lazy(() => import('./pages/GradesPage')));
const StudentsPage = Loadable(lazy(() => import('./pages/StudentsPage')));
const StudentPage = Loadable(lazy(() => import('./pages/StudentPage')));

//EVENTS
const EventDistrictPage = Loadable(lazy(() => import('./pages/EventDistrictPage')));
const EventQuickJoinPage = Loadable(lazy(() => import('./pages/EventQuickJoinPage')));
const EventQuickJoinInvitePage = Loadable(lazy(() => import('./pages/EventQuickJoinInvitePage')));

export const studentRoutes = [
  { path: '/', element: <DashboardStudentsPage /> },
  { path: '/teacher-registration', element: <RegisterTeacherPage /> },
  { path: '/home', element: <HomePage /> },
  { path: 'login', element: <LoginPage /> },
  { path: 'register', element: <RegisterPage /> },
  { path: 'classes', element: <CoursesPage /> },
  { path: 'classes/:courseId', element: <CoursePage /> },
  { path: 'classes/:courseId/assignments/:assignmentId', element: <AssignmentPage /> },
  { path: 'classes/:courseId/assignments/:assignmentId/opinions/:opinionId', element: <OpinionPage /> },
  { path: 'classes/:courseId/assignments/:assignmentId/opinions/:opinionId/responses/:responseId', element: <ResponsePage /> },
  { path: 'classes/:courseId/assignments/:assignmentId/opinions/:opinionId/responses/:responseId/replies/:replyId', element: <ReplyPage /> },
  { path: 'create/:courseId/assignments/:assignmentId/opinions/', element: <OpinionCreatePage /> },
  { path: 'create/:courseId/assignments/:assignmentId/opinions/:opinionId', element: <OpinionCreatePage /> },
  { path: 'create/:courseId/assignments/:assignmentId/opinions/:opinionId/responses/', element: <ResponseCreatePage /> },
  { path: 'create/:courseId/assignments/:assignmentId/opinions/:opinionId/responses/:responseId', element: <ResponseCreatePage /> },
  { path: 'create/:courseId/assignments/:assignmentId/opinions/:opinionId/responses/:responseId/replies/', element: <ReplyCreatePage /> },
  { path: 'create/:courseId/assignments/:assignmentId/opinions/:opinionId/responses/:responseId/replies/:replyId', element: <ReplyCreatePage /> },
  { path: 'assignments', element: <AssignmentsPage /> },
  { path: 'dashboard', element: <DashboardStudentsPage /> },
  { path: 'generate/:courseId/:assignmentId', element: <AssignmentUserGeneratorPage />},
  {
    path: 'generate/:courseId/assignments/:assignmentId/',
    element: <AssignmentUserGeneratorPage />,
    children: [
      {
        path: ':courseId/:assignmentId/opinions/:opinionId',
        // element: <AssignmentUserGeneratorPage />
      },
      {
        path: ':courseId/assignments/:assignmentId/opinions/:opinionId/responses/:responseId',
        // element: <AssignmentUserGeneratorPage />
      },
      {
        path: ':courseId/assignments/:assignmentId/opinions/:opinionId/responses/:responseId/replies/:replyId',
        // element: <AssignmentUserGeneratorPage />
      }
    ]
  },
  { path: 'review', element: <SplitReviewPage /> },
  { path: 'review/:courseId/assignments/:assignmentId/opinions/:opinionId', element: <SplitReviewPage /> },
  { path: 'review/:courseId/assignments/:assignmentId/opinions/:opinionId/responses/:responseId', element: <SplitReviewPage /> },
  { path: 'review/:courseId/assignments/:assignmentId/opinions/:opinionId/responses/:responseId/replies/:replyId', element: <SplitReviewPage /> },
  { path: 'dashboard/opinion', element: <DashboardStudentsPage tab="opinion" /> },
  { path: 'avatar', element: <AvatarGenerator /> },
  { path: 'email-sig', element: <EmailSignaturePage /> },
  { path: 'settings', element: <SettingsPage /> },
  { path: 'feedback', element: <FeedbackPage /> },
  { path: 'messages', element: <MessagesPage /> },
  { path: 'grades', element: <GradesPage /> },
  { path: 'meetings', element: <MeetingsPage /> },
  // { path: 'messages/:interlocutorId', element: <MessagesPage /> },

  // { path: 'hangouts', element: <Hangouts /> },
  // {
  //   path: '401',
  //   element: <AuthorizationRequired />
  // },
  // {
  //   path: '404',
  //   element: <NotFound />
  // },
  // {
  //   path: '500',
  //   element: <ServerError />
  // },
  {
    path: '/*',
    element: <NotFoundPage />
  }
];

export const teacherRoutes = [
  { path: '/', element: <DashboardTeachersPage /> },
  { path: '/teacher-registration', element: <RegisterTeacherPage /> },
  { path: '/home', element: <HomeTeacherPage /> },
  { path: 'login', element: <LoginPage /> },
  { path: 'register', element: <RegisterPage /> },
  { path: 'classes', element: <CoursesPage /> },
  { path: 'classes/:courseId', element: <CoursePage /> },
  { path: 'classes/:courseId/assignments/:assignmentId', element: <AssignmentPage /> },
  { path: 'classes/:courseId/assignments/:assignmentId/opinions/:opinionId', element: <OpinionPage /> },
  { path: 'classes/:courseId/assignments/:assignmentId/opinions/:opinionId/responses/:responseId', element: <ResponsePage /> },
  { path: 'classes/:courseId/assignments/:assignmentId/opinions/:opinionId/responses/:responseId/replies/:replyId', element: <ReplyPage /> },
  { path: 'create/:courseId/assignments/:assignmentId/opinions/', element: <OpinionCreatePage /> },
  { path: 'create/:courseId/assignments/:assignmentId/opinions/:opinionId', element: <OpinionCreatePage /> },
  { path: 'create/:courseId/assignments/:assignmentId/opinions/:opinionId/responses/', element: <ResponseCreatePage /> },
  { path: 'create/:courseId/assignments/:assignmentId/opinions/:opinionId/responses/:responseId', element: <ResponseCreatePage /> },
  { path: 'create/:courseId/assignments/:assignmentId/opinions/:opinionId/responses/:responseId/replies/', element: <ReplyCreatePage /> },
  { path: 'create/:courseId/assignments/:assignmentId/opinions/:opinionId/responses/:responseId/replies/:replyId', element: <ReplyCreatePage /> },
  { path: 'assignments/:assignmentId', element: <AssignmentPage /> },
  { path: 'assignments', element: <AssignmentsPage /> },
  { path: 'dashboard', element: <DashboardTeachersPage /> },
  { path: 'students', element: <StudentsPage /> },
  { path: 'students/:studentId', element: <StudentPage /> },
  { path: 'generate/:courseId/:assignmentId', element: <AssignmentUserGeneratorPage />},
  {
    path: 'generate/:courseId/assignments/:assignmentId/',
    element: <AssignmentUserGeneratorPage />,
    children: [
      {
        path: ':courseId/:assignmentId/opinions/:opinionId',
        // element: <AssignmentUserGeneratorPage />
      },
      {
        path: ':courseId/assignments/:assignmentId/opinions/:opinionId/responses/:responseId',
        // element: <AssignmentUserGeneratorPage />
      },
      {
        path: ':courseId/assignments/:assignmentId/opinions/:opinionId/responses/:responseId/replies/:replyId',
        // element: <AssignmentUserGeneratorPage />
      }
    ]
  },
  { path: 'review', element: <SplitReviewPage /> },
  { path: 'review/:courseId/assignments/:assignmentId/opinions/:opinionId', element: <SplitReviewPage /> },
  { path: 'review/:courseId/assignments/:assignmentId/opinions/:opinionId/responses/:responseId', element: <SplitReviewPage /> },
  { path: 'review/:courseId/assignments/:assignmentId/opinions/:opinionId/responses/:responseId/replies/:replyId', element: <SplitReviewPage /> },
  { path: 'dashboard/opinion', element: <DashboardTeachersPage tab="opinion" /> },
  { path: 'grading', element: <GradingPage /> },
  { path: 'grading/:courseId/assignments/:assignmentId/opinions/:opinionId', element: <SplitGradingPage /> },
  { path: 'grading/:courseId/assignments/:assignmentId/opinions/:opinionId/responses/:responseId', element: <SplitGradingPage /> },
  { path: 'grading/:courseId/assignments/:assignmentId/opinions/:opinionId/responses/:responseId/replies/:replyId', element: <SplitGradingPage /> },
  { path: 'grading/:courseId/assignments/:assignmentId/student/:studentId', element: <SplitGradingPage /> },
  { path: 'grading/assess', element: <SplitGradingPage /> },
  { path: 'avatar', element: <AvatarGenerator /> },
  { path: 'email-sig', element: <EmailSignaturePage /> },
  { path: 'settings', element: <SettingsPage /> },
  { path: 'feedback', element: <FeedbackPage /> },
  { path: 'messages', element: <MessagesPage /> },
  { path: 'event-district', element: <EventDistrictPage /> },
  // { path: 'messages/:interlocutorId', element: <MessagesPage /> },

  // { path: 'hangouts', element: <Hangouts /> },
  // {
  //   path: '401',
  //   element: <AuthorizationRequired />
  // },
  // {
  //   path: '404',
  //   element: <NotFound />
  // },
  // {
  //   path: '500',
  //   element: <ServerError />
  // },
  {
    path: '/*',
    element: <NotFoundPage />
  }
];

export const guestRoutes = [
  { path: '/', element: <LandingPage /> },
  { path: '/teacher-registration', element: <RegisterTeacherPage /> },
  { path: 'login', element: <LoginPage /> },
  { path: 'register', element: <RegisterPage /> },
  { path: 'meetings', element: <MeetingsPage /> },
  { path: 'event-district', element: <EventDistrictPage /> },
  { path: 'event-quick-join', element: <EventQuickJoinPage /> },
  { path: 'event-quick-join-invite', element: <EventQuickJoinInvitePage /> },
  {
    path: '/*',
    element: <NotFoundPage />
  }
];


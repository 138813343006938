import React, { useState } from 'react'
import { features, quizFeatures } from '../../lib/ProductLib'
import { Divider, Grid, Typography } from '@mui/material'
import { useRef } from 'react';
import { useEffect } from 'react';
import { ButtonCust } from '../ui/Buttons';

const QuizFeatureList = (props) => {
  const { handleClickOpen } = props;
  const [selectedFeature, setSelectedFeature] = useState(null);

  return (
    <>
      <Grid
        container
        id="quiz"
        sx={{
          mt:8,
          pt:2
        }}
      >
        <Grid
          container
        >
          <Divider sx={{width:'100%', my:4}}>
            <Typography
              variant="h3"
              sx={{
                lineHeight:0
                // mt:-4
              }}
            >
              Opine Quiz
            </Typography>
          </Divider>
            
        </Grid>

        <Grid
          container
        >
          <Typography sx={{whiteSpace:'pre-line'}} variant="h5">
            {`Opine Quiz was developed to provide a free and lasting solution to teachers and students that need popular products like Kahoot or Quizlet. While these products are great, they cost more than some classroom budgets allow and teachers are often stuck paying the bill from their personal budget.
            
            Opine intends to keep Opine Quiz as a free product and we only ask for subscription fees from teachers who have a classroom budget to support it.
            
            What can you do with Opine Quiz? We're glad you asked...`} 
          </Typography>
        </Grid>
        <Grid
          container
          sx={{
            alignItems:'stretch',
            mt:6
          }}
        >
          {
            quizFeatures.studysets.map((feature, index) => (
              <QuizItem
                onClick={() => setSelectedFeature(feature.label)}
                selectedFeature={selectedFeature}
                key={feature.label}
                feature={feature}
                index={index}
              />
            ))
          }
          {
            quizFeatures.quizzes.map((feature, index) => (
              <QuizItem
                onClick={() => setSelectedFeature(feature.label)}
                selectedFeature={selectedFeature}
                key={feature.label}
                feature={feature}
                index={index}
              />
            ))
          }

          <Grid
            item
            xs
          >
            <Grid
              container
              sx={{
                m:2,
                px:2,
                py:4,
                width:'calc(100% - 32px)',
                height:'calc(100% - 32px)',
                borderRadius:3,
                boxShadow:'0 0 10px #00000030',
                justifyContent:'center',
                flexDisplay:'column',
                alignItems:'center',
                background:'#fcfcfc'
              }}
            >
              <Grid
                sx={{
                  flexDisplay:'center',
                  justifyContent:'center',
                  alignItems:'center'
                }}
              >
                <Typography
                  variant="h4"
                  textAlign="center"
                >
                  Find out more
                </Typography>

                <Grid
                  sx={{
                    textAlign:'center',
                    mb:2
                  }}
                >
                  <ButtonCust
                    onClick={() => handleClickOpen('register')}
                    sx={{
                      mt:{xs:2, md:4},
                      fontSize:{
                        xs:22, md:'1.5vw'
                      },
                      fontWeight:{
                        xs:600,
                        md:500
                      },
                      padding:{
                        xs:'14px 20px 14px 20px',
                        md:'20px 40px 20px 40px'
                      }
                    }}
                    buttonProps={{
                    }}
                    variant="contained"
                    color1Start="brand.nine"
                    color1End="brand.ten"
                    color2Start="brand.five"
                    color2End="brand.six"
                    angle="135deg"
                  >
                    Join The Beta
                  </ButtonCust>
                </Grid>

                <a
                  href="mailto:contact@opineschool.com"
                  style={{
                    textDecoration:'none'
                  }}
                >
                  <Typography
                    style={{
                      textDecoration:'none',
                      width:'100%',
                      textAlign:'center'
                    }}
                  >
                    Or email us at contact@opineschool.com
                  </Typography>
                </a>

              </Grid>
            </Grid>
          </Grid>
        </Grid>

          
      </Grid>
        
    </>
  )
}

export default QuizFeatureList

const QuizItem = (props) => {
  const { feature, index, onClick, selectedFeature } = props;

  const [imageHeight, setImageHeight] = useState(0);

  const descriptionRef = useRef(null);

  useEffect(() => {
    if (descriptionRef.current) {
      setImageHeight(descriptionRef.current.clientHeight+32);
    }
  }, []);

  return (
    <Grid
      container
      onClick={onClick}
      sx={{
        p: 2,
        borderRadius: 3,
        boxShadow: '0 0 10px #00000030',
        background:'#fcfcfc',
        mb: 2
      }}
    >
      <Grid
        item
        id="quiz-image-container"
        width={{xs:'100%', md:200}}
      >
        {
          feature.img &&
            <img
              src={feature.img}
              loading="lazy"
              style={{
                width: '100%',
                objectFit: 'cover',
                objectPosition: 'center',
                borderRadius: 8,
                height: imageHeight,
                minHeight:100
              }}
            />
        }
      </Grid>

      <Grid
        item
        id="quiz-feature-description"
        sx={{
          px: 4,
          display:'flex',
          flexDirection:'column',
          justifyContent:'center'
        }}
        xs
      >
        <Typography variant="h4">
          {feature.label}
        </Typography>
        <Typography
          sx={{
            lineHeight: 1.3
          }}
          ref={descriptionRef}
        >
          {feature.description}
        </Typography>
      </Grid>
    </Grid>
  );
}

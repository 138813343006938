export const features = [
  {
    label:'Assignment Creation',
    description:`Effortlessly create engaging assignments tailored to your curriculum and teaching objectives with our intuitive assignment creation feature.
    `,
    // icon: 'https://firebasestorage.googleapis.com/v0/b/civil-5a1b9.appspot.com/o/static%2Fimg%2FAssignment_Creation_1280x1280_alpha.png?alt=media&token=02bbf223-640f-4248-bfae-c3c7cc8bcbcf',
    img: 'https://firebasestorage.googleapis.com/v0/b/opine-production.appspot.com/o/static%2Fimg%2FAssignment_Creation_thumb.png?alt=media&token=7dd59619-8f3d-45b6-be1e-c3eecfc9f1c0'
  },
  {
    label:'Submission Deadlines',
    description:`Set clear deadlines for student submissions, ensuring timely completion of assignments and effective time management for both you and your students.
    `,
    // icon: 'https://firebasestorage.googleapis.com/v0/b/civil-5a1b9.appspot.com/o/static%2Fimg%2FSubmission_Deadlines_1280x1280_alpha.png?alt=media&token=08b4d256-4f8b-4eab-b35a-6eabfe41a458',
    img: 'https://firebasestorage.googleapis.com/v0/b/opine-production.appspot.com/o/static%2Fimg%2FSubmission_Deadlines_thumb.png?alt=media&token=e01608b4-6be8-41a1-95b5-4504bf77144c'
  },
  {
    label:'In-App Submissions',
    description:`Streamline the assignment submission process by allowing students to submit their work directly within the app, eliminating the need for physical paperwork.
    `,
    // icon: 'https://firebasestorage.googleapis.com/v0/b/civil-5a1b9.appspot.com/o/static%2Fimg%2FIn-App_Submissions_1280x1280_alpha.png?alt=media&token=db93ab32-a809-4c96-a135-7ed44d5f0bae',
    img: 'https://firebasestorage.googleapis.com/v0/b/opine-production.appspot.com/o/static%2Fimg%2FIn-App_Submissions_thumb.png?alt=media&token=84b6391c-dd4f-40b4-99a3-2ff5cdd778b6'
  },
  {
    label:'Anonymous Submissions',
    description:`Enable anonymous student submissions, nurturing critical thinking by removing pre-determined views and embracing diverse perspectives.
    `,
    // icon: 'https://firebasestorage.googleapis.com/v0/b/civil-5a1b9.appspot.com/o/static%2Fimg%2FAnonymous_Submissions_1280x1280_alpha.png?alt=media&token=8fab9d20-7b0e-4caf-9486-27de1b2c1526',
    img: 'https://firebasestorage.googleapis.com/v0/b/opine-production.appspot.com/o/static%2Fimg%2FAnonymous_Submissions_thumb.png?alt=media&token=d92cd379-cb4a-4db0-9acc-f98df86e983b'
  },
  {
    label:'Submission Review',
    description:`Review all submissions before they are visible to the entire class, ensuring quality and appropriate content.
    `,
    // icon: 'https://firebasestorage.googleapis.com/v0/b/civil-5a1b9.appspot.com/o/static%2Fimg%2FSubmission_Review_1280x1280_alpha.png?alt=media&token=77f060f6-43be-4c4a-b48c-fc82fce8f5d0',
    img: 'https://firebasestorage.googleapis.com/v0/b/opine-production.appspot.com/o/static%2Fimg%2FSubmission_Review_thumb.png?alt=media&token=47b61bf5-eda4-47b0-8ea5-10fdc5fe0035'
  },
  {
    label:'Commenting System',
    description:`Encourage collaborative learning and insightful discussions among students with our integrated comment feature, facilitating meaningful feedback and interactions.
    `,
    // icon: 'https://firebasestorage.googleapis.com/v0/b/civil-5a1b9.appspot.com/o/static%2Fimg%2FCommenting_System_1280x1280_alpha.png?alt=media&token=c3ee4340-5a28-450c-aa2c-8caff04620fa',
    img: 'https://firebasestorage.googleapis.com/v0/b/opine-production.appspot.com/o/static%2Fimg%2FCommenting_System_thumb.png?alt=media&token=3d889ea2-2413-44dd-8c55-139475e4b4ef'
  },
  {
    label:'Assignment Parts',
    description:`Structure assignments with clarity and promote critical thinking through our Opinion, Response, and Reply framework, mirroring the rigor of legal court proceedings.
    `,
    // icon: 'https://firebasestorage.googleapis.com/v0/b/civil-5a1b9.appspot.com/o/static%2Fimg%2FAssignment_Parts_1280x1280_alpha.png?alt=media&token=b343f0fa-a2ef-4534-b1f9-12b275ab77e5',
    img: 'https://firebasestorage.googleapis.com/v0/b/opine-production.appspot.com/o/static%2Fimg%2FAssignment_Parts_thumb.png?alt=media&token=c049d1a1-6191-4757-9dd9-280bc3c3e3cc'
  },
  {
    label:'Exhibit-Based Submissions',
    description:`Enhance students' analytical skills by requiring evidence-backed arguments through exhibits, encouraging thoughtful responses and improving overall argumentation quality.
    `,
    // icon: 'https://firebasestorage.googleapis.com/v0/b/civil-5a1b9.appspot.com/o/static%2Fimg%2FExhibit-Based_Submissions_1280x1280_alpha.png?alt=media&token=0f5e7110-9df5-4c69-817c-7537e604f8c0',
    img: 'https://firebasestorage.googleapis.com/v0/b/opine-production.appspot.com/o/static%2Fimg%2FExhibit-Based_Submissions_thumb.png?alt=media&token=5eafa69b-4a7d-4a56-8bf4-7111400bcc06'
  },
  {
    label:'Opinion Grading',
    description:`Efficiently assess and grade students' initial opinion submissions individually, providing personalized feedback to nurture their analytical and persuasive writing skills.
    `,
    // icon: 'https://firebasestorage.googleapis.com/v0/b/civil-5a1b9.appspot.com/o/static%2Fimg%2FOpinion_Grading_1280x1280_alpha.png?alt=media&token=410fa991-f04e-42b3-9c9a-a23602093ff0',
    img: 'https://firebasestorage.googleapis.com/v0/b/opine-production.appspot.com/o/static%2Fimg%2FOpinion_Grading_thumb.png?alt=media&token=989c1b82-c1c8-445c-a546-01a9eb40b710'
  },
  {
    label:'Response Grading',
    description:`Evaluate students' responses to foster constructive dialogue, offering targeted feedback to help them refine their argumentative abilities.
    `,
    // icon: 'https://firebasestorage.googleapis.com/v0/b/civil-5a1b9.appspot.com/o/static%2Fimg%2FResponse_Grading_1280x1280_alpha.png?alt=media&token=f813cbf7-4b9b-4152-a61c-4c3327bb1b93',
    img: 'https://firebasestorage.googleapis.com/v0/b/opine-production.appspot.com/o/static%2Fimg%2FResponse_Grading_thumb.png?alt=media&token=81030069-f6bc-4fd8-aef4-54b9ef2d67ca'
  },
  {
    label:'Reply Grading',
    description:`Encourage concise and evidence-based rebuttals by grading students' replies individually, fostering critical thinking and logical reasoning.
    `,
    // icon: 'https://firebasestorage.googleapis.com/v0/b/civil-5a1b9.appspot.com/o/static%2Fimg%2FReply_Grading_1280x1280_alpha.png?alt=media&token=0faff441-1a4c-4915-9a27-c75aec587db2',
    img: 'https://firebasestorage.googleapis.com/v0/b/opine-production.appspot.com/o/static%2Fimg%2FReply_Grading_thumb.png?alt=media&token=413e6e4e-4856-49dd-8802-54ef38c10609'
  },
  {
    label:'Group Grading',
    description:`Save time and simplify your grading workflow by assessing all opinions, responses, and replies as a cohesive assignment for each student.
    `,
    // icon: 'https://firebasestorage.googleapis.com/v0/b/civil-5a1b9.appspot.com/o/static%2Fimg%2FGroup_Grading_1280x1280_alpha.png?alt=media&token=7e1898e0-f4fd-4668-ab9f-6c2d492b4a37',
    img: 'https://firebasestorage.googleapis.com/v0/b/opine-production.appspot.com/o/static%2Fimg%2FGroup_Grading_thumb.png?alt=media&token=640023c8-281b-4d22-a2dc-84ee1f9eabab'
  },
  {
    label:'Submission Notifications',
    description:`Stay up-to-date with real-time notifications, allowing you to promptly review and provide feedback on completed assignments, keeping students engaged and motivated.
    `,
    // icon: 'https://firebasestorage.googleapis.com/v0/b/civil-5a1b9.appspot.com/o/static%2Fimg%2FSubmission_Notifications_1280x1280_alpha.png?alt=media&token=e92a7b21-b990-4ba7-846e-293b16b8c62b',
    img: 'https://firebasestorage.googleapis.com/v0/b/opine-production.appspot.com/o/static%2Fimg%2FSubmission_Notifications_thumb.png?alt=media&token=088590d3-063e-4bba-a26e-3d3cb4b6ef48'
  },
]

export const quizFeatures = {
    studysets:[
      {
        label:'Effortless Import and Modification',
        description:`Save time and unlock creativity by seamlessly importing study cards from other teachers or existing sets. Modify study sets with ease, adapting them to your teaching style and evolving curriculum.
        `,
        // icon: 'https://firebasestorage.googleapis.com/v0/b/civil-5a1b9.appspot.com/o/static%2Fimg%2FAssignment_Creation_1280x1280_alpha.png?alt=media&token=02bbf223-640f-4248-bfae-c3c7cc8bcbcf',
        img: 'https://firebasestorage.googleapis.com/v0/b/opine-production.appspot.com/o/static%2Fimg%2FQuiz_Imports_thumb.png?alt=media&token=493c57f4-45da-458a-9733-f01fd88d6337'
      },
      {
        label:'Class-Based Organization',
        description:`Easily organize study sets based on different classes or subjects, keeping your teaching materials well-structured and readily accessible.
        `,
        // icon: 'https://firebasestorage.googleapis.com/v0/b/civil-5a1b9.appspot.com/o/static%2Fimg%2FAssignment_Creation_1280x1280_alpha.png?alt=media&token=02bbf223-640f-4248-bfae-c3c7cc8bcbcf',
        img: 'https://firebasestorage.googleapis.com/v0/b/opine-production.appspot.com/o/static%2Fimg%2FQuiz_Class_Organization_thumb.png?alt=media&token=ae911dac-f743-42bc-bd75-23ec267175f0'
      },
      {
        label:'Period-Specific Customization',
        description:`Study Sets: Create study sets tailored to each class period's unique learning objectives, ensuring targeted and efficient review sessions.
        `,
        // icon: 'https://firebasestorage.googleapis.com/v0/b/civil-5a1b9.appspot.com/o/static%2Fimg%2FAssignment_Creation_1280x1280_alpha.png?alt=media&token=02bbf223-640f-4248-bfae-c3c7cc8bcbcf',
        img: 'https://firebasestorage.googleapis.com/v0/b/opine-production.appspot.com/o/static%2Fimg%2FQuiz_Period_Specific_thumb.png?alt=media&token=41598648-b888-45f5-9840-cf43b204bfb7'
      },
      {
        label:'Dynamic Study Sets',
        description:`Ignite student curiosity and mastery with captivating study sets. Add images to questions and answers, transforming learning into an immersive experience that leaves a lasting impact.
        `,
        // icon: 'https://firebasestorage.googleapis.com/v0/b/civil-5a1b9.appspot.com/o/static%2Fimg%2FAssignment_Creation_1280x1280_alpha.png?alt=media&token=02bbf223-640f-4248-bfae-c3c7cc8bcbcf',
        img: 'https://firebasestorage.googleapis.com/v0/b/opine-production.appspot.com/o/static%2Fimg%2FQuiz_Dynamic_Study_Sets_thumb.png?alt=media&token=b6991233-be1c-4eb1-ac77-c1e8d854264d'
      },
      {
        label:'Flexible Answer Generation',
        description:`Unleash the power of adaptive assessments. Choose between generating incorrect answers from correct answers or customizing correct and incorrect options for each question. Tailor quizzes to meet the specific needs of your students.
        `,
        // icon: 'https://firebasestorage.googleapis.com/v0/b/civil-5a1b9.appspot.com/o/static%2Fimg%2FAssignment_Creation_1280x1280_alpha.png?alt=media&token=02bbf223-640f-4248-bfae-c3c7cc8bcbcf',
        img: 'https://firebasestorage.googleapis.com/v0/b/opine-production.appspot.com/o/static%2Fimg%2FQuiz_Answer_Generation_thumb.png?alt=media&token=c8e7f4a9-74a7-49cf-9f8a-b553a90f2da2'
      },
      {
        label:'Collaborative Quizzes',
        description:`Transform your classroom into a hub of teamwork and collaboration. Automatically divide students into teams and prompt them to work together, leveraging collective knowledge to solve quiz questions. Foster collaboration and critical thinking skills.
        `,
        // icon: 'https://firebasestorage.googleapis.com/v0/b/civil-5a1b9.appspot.com/o/static%2Fimg%2FAssignment_Creation_1280x1280_alpha.png?alt=media&token=02bbf223-640f-4248-bfae-c3c7cc8bcbcf',
        img: 'https://firebasestorage.googleapis.com/v0/b/opine-production.appspot.com/o/static%2Fimg%2FQuiz_Collaborative_Quizzes_thumb.png?alt=media&token=ca230d94-2eb9-4e80-98ae-44c7dee64c01'
      },
      {
        label:'Seamless Student Access',
        description:`Make learning accessible and hassle-free. Students can access content through simple links or invite codes, eliminating the need for creating accounts. Experience frictionless engagement and maximize instructional time.
        `,
        // icon: 'https://firebasestorage.googleapis.com/v0/b/civil-5a1b9.appspot.com/o/static%2Fimg%2FAssignment_Creation_1280x1280_alpha.png?alt=media&token=02bbf223-640f-4248-bfae-c3c7cc8bcbcf',
        img: 'https://firebasestorage.googleapis.com/v0/b/opine-production.appspot.com/o/static%2Fimg%2FQuiz_Student_Access_thumb.png?alt=media&token=a126056a-2953-41b2-966d-539fdbd8c07f'
      },
    ],
    quizzes:[
      {
        label:'Dynamic Quiz Creation',
        description:`Create captivating quizzes from your study sets. Choose between linking quizzes directly to study sets, ensuring real-time updates, or duplicating study sets for quizzes, preserving your carefully curated content. Take control of your assessments.
        `,
        // icon: 'https://firebasestorage.googleapis.com/v0/b/civil-5a1b9.appspot.com/o/static%2Fimg%2FAssignment_Creation_1280x1280_alpha.png?alt=media&token=02bbf223-640f-4248-bfae-c3c7cc8bcbcf',
        img: 'https://firebasestorage.googleapis.com/v0/b/opine-production.appspot.com/o/static%2Fimg%2FQuiz_Dynamic_Quizzes_thumb.png?alt=media&token=8021e600-3ee9-4f29-a9ee-6836fbc86dc5'
      },
      {
        label:'Empower Student Collaboration',
        description:`Only one student per team is guaranteed to have the correct answer on their device, fostering teamwork and critical thinking as students must collaborate to find the right answer.
        `,
        // icon: 'https://firebasestorage.googleapis.com/v0/b/civil-5a1b9.appspot.com/o/static%2Fimg%2FAssignment_Creation_1280x1280_alpha.png?alt=media&token=02bbf223-640f-4248-bfae-c3c7cc8bcbcf',
        img: 'https://firebasestorage.googleapis.com/v0/b/opine-production.appspot.com/o/static%2Fimg%2FQuiz_Student_Collaboration_thumb.png?alt=media&token=cc94be6e-6415-4c4c-8d80-dc89cd0ce650'
      },
      {
        label:'Real-time Progress Monitoring',
        description:`Display an interactive screen during the quiz, showcasing the unique invite code for students to join. Monitor each team's progress with the dynamic scoreboard, providing instant feedback and encouraging healthy competition.
        `,
        // icon: 'https://firebasestorage.googleapis.com/v0/b/civil-5a1b9.appspot.com/o/static%2Fimg%2FAssignment_Creation_1280x1280_alpha.png?alt=media&token=02bbf223-640f-4248-bfae-c3c7cc8bcbcf',
        img: 'https://firebasestorage.googleapis.com/v0/b/opine-production.appspot.com/o/static%2Fimg%2FQuiz_Progress_Monitoring_thumb.png?alt=media&token=7e0490cb-e7ae-439c-8080-86852eab30d6'
      },
    ],
  }

import React, { useState } from 'react'
import { ArrowDownward, ArrowDownwardRounded, Favorite, HeartBroken, KeyboardArrowDownRounded } from '@mui/icons-material'
import { Button, Grid, IconButton, Typography, useMediaQuery } from '@mui/material'
import { useTheme } from '@emotion/react';
import { ButtonCust } from '../ui/Buttons';
import { hashScroll } from '../../lib/Functions';

const SectionTemplate = (props) => {
  const {
    anchor,
    align,
    content,
    theme,
    gradient1,
    gradient2,
    title,
    imgLight,
    imgDark,
    imgFigure,
    handleClickOpen,
    survey,
    surveyTitle,
    surveyBody,
    surveyForm,
    surveyAlign,
    surveyBackgroundColor,
    surveyTextColor
  } = props;
  const mobileTheme = useTheme();
  const isMobile = useMediaQuery(mobileTheme.breakpoints.down('sm'), {defaultMatches: true})

  // Switch between alternative copy
  const [positive, setPositive] = useState(false);
  const handlePositive = () => {
    if (positive === true) {
      setPositive(false);
    } else {
      setPositive(true);
    }
  }

  // ModalSignUp Functions
  const [openModalSignUp, setOpenModalSignUp] = useState(false);
  const [sent, setSent] = useState(false);

  const handleOpenModalSignUp = () => {
    setOpenModalSignUp(true);
  };

  const handleCloseModalSignUp = () => {
    setOpenModalSignUp(false);
    setSent(false);
  };
  

  return (
    <>
      <Grid
        className={`${title}-1`}
        id={anchor}
        container
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        pt={{xs:8, md:18}}
        // pb={{xs:16, md:8, lg:40}}
        sx={{
          position:'relative',
          // background: `linear-gradient(-45deg, ${mobileTheme.palette.royal.main}ef, ${mobileTheme.palette.playful.main}ee), ${imgDark})`,  // themeColor, //themeElements[theme].background,
          background: `linear-gradient(-45deg, ${gradient1}00 40%, ${gradient2}ff), url(${theme === 'light' ? imgLight : imgDark})`,
          // background: 'linear-gradient(45deg, blue, red)',
          // backgroundImage: {sm: theme === 'light' ? imgLight : imgDark, xs: imgFigure},
          // backgroundPosition: {xs:'bottom right' , sm:'center right'},
          backgroundPosition: {xs:'68% 0%' , sm:'100% 0%', md:'0px 20%'},
          backgroundRepeat:'no-repeat',
          backgroundSize:{xs:'1500px', sm:'1500px', md:'cover'},
          minHeight:{lg:'100vh'}
        }}
      >
        {/* <img
          src={isMobile ? imgFigure : theme === 'light' ? imgLight : imgDark}
          alt="image"
          style={{
            position:'absolute',
            bottom:0,
            right:0,
            width:isMobile ? '70%' : '100%',
            height:isMobile ? 'inherit' : '100%',
            objectFit:'cover'
          }}
        /> */}
        <Grid
          className={`${title}-2`}
          xs={10}
        >
          <Grid
            className={`${title}-3`}
            container
            justifyContent={align}
            // py={{xs:2, md:18}}
            sx={{
            }}
          >
            <Grid
              container
              className="Who Section Top Level"
              sx={{
                mt:{xs:8, md:0},
                zIndex:1000
              }}
              md={6}
              sm={9}
              xs={12}
            >
              <Typography variant="h2" sx={{fontSize:{xs:32, md:'5.5vw' , lg:'3.5vw', xl:'5vw', fontWeight:800}, color: {xs:'#fff', sm: mobileTheme.palette.text.dark}}}>
                {content.headline}
              </Typography>
              <Grid sx={{position:'absolute', left: isMobile ? '0px' : '40px'}}>
              </Grid>
              <Typography variant="h4" color="deep.main" sx={{fontSize:{xs:24, md:'2vw'}, color: {xs:'#fff'}, textShadow:'0 0 5px #00000080'}} mt={{xs:2, md:5}}>
                {content.body}
              </Typography>
              {/* <Button
                variant="contained"
                color="secondary"
                onClick={() => handleClickOpen('register')}
                target="_blank"
                sx={{
                  mt:5,
                  fontSize:{
                    xs:16, md:'2vw'
                  },
                  fontWeight:{
                    xs:600,
                    md:500
                  },
                  padding:{
                    xs:'10px 15px 10px 15px',
                    md:'20px 40px 20px 40px'
                  }
                }}
              >
                {content.cta}
              </Button>  */}
              <ButtonCust
                onClick={() => handleClickOpen('register')}
                sx={{
                  mt:5,
                  fontSize:{
                    xs:26, md:'2vw'
                  },
                  fontWeight:{
                    xs:600,
                    md:500
                  },
                  padding:{
                    xs:'14px 20px 14px 20px',
                    md:'20px 40px 20px 40px'
                  }
                }}
                buttonProps={{
                }}
                variant="contained"
                color1Start="brand.nine"
                color1End="brand.ten"
                color2Start="brand.five"
                color2End="brand.six"
                angle="135deg"
              >
                {content.cta}
              </ButtonCust>
                

            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            justifyContent:'center'
          }}
        >
          <Button
            onClick={() => hashScroll('features')}
            sx={{
              flexDirection:'column',
              fontSize:24,
              color:'#ffffff',
              px:3,
              pt:2,
              background:'#00000090',
              '&&:hover':{
                background:'#000000bb'
              }
            }}
          >
            See More <KeyboardArrowDownRounded sx={{fontSize:36}} />
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

export default SectionTemplate
import React, { useEffect, useState } from 'react'
import { Close, Info, InfoOutlined } from '@mui/icons-material'; 
import { useTheme } from '@emotion/react';
import {
  Dialog,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  Tooltip,
  Box
} from '@mui/material';
import { useAuth } from '../../context/authContext';

const InfoModalTemplate = (props) => {
  const {
    colorBackground,
    details,
    setWidth,
    submitted,
    info,
    placement,
    teacher,
    student,
    sub
    // infoDetails - Create additional section for further info details?
  } = props;

  const authContext = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {defaultMatches: true})

  const [maxWidth, setMaxWidth] = useState(setWidth ? setWidth : 'md');
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  // useEffect(() => {
  //   setOpenDialog(false);
  // }, [submitted])

  return (
    <>
    {authContext.account.settings?.showInfo &&
    <>
      <Tooltip
        title={
          <Typography
            sx={{
              color:'#fff',
              fontSize:16
            }}
          >
            {info ? info : ''} {authContext.isTeacher ? (teacher ? teacher : '') : (student ? student : '')}
          </Typography>
        }
        placement={placement ? placement : 'right'}
        arrow
      >
        <Box
          sx={{
            display:'flex',
            alignItems:'center'
          }}
        >
          <IconButton  
            onClick={handleOpenDialog}
            sx={{
              height:40,
              width:40
            }}
          >
            <InfoOutlined/>
          </IconButton>
        </Box>
      </Tooltip>

      <Dialog
        fullScreen={isMobile ? true : false}
        // fullWidth={setWidth ? false : true}
        maxWidth={maxWidth}
        scroll='body'
        open={openDialog}
        onClose={handleCloseDialog}
        PaperProps={{
          sx: {
            background: `linear-gradient(${colorBackground},${colorBackground})`,
            backgroundColor: '#fff'
          }
        }}
      >
        <Grid
          container
          justifyContent='center'
        >
          <Grid
            item
            xs={10}
          >
            <Box
              sx={{
                display:'flex',
                alignItems:'center',
                justifyContent:'center',
                mt:{xs:2}
              }}
            >
              {/* <Typography
                variant='h4'
                sx={{
                  textAlign:'center'
                }}
                >
                Info
              </Typography> */}
              <Info sx={{fontSize:36, color:'#555'}} />
            </Box>
          </Grid>
          <Box
            sx={{
              position:'absolute',
              top:10,
              right:10
            }}
          >
            <IconButton onClick={handleCloseDialog}><Close sx={{fontSize:24}} /></IconButton>
          </Box>
          <Grid 
            item 
            xs={12}             
            sx={{
              px:{xs:1, sm:5},
              pb:{xs:1, sm:5},
              mt:1
            }}
          >
            <Typography
              sx={{
                textAlign:'center',
                fontWeight:500
              }}
            >
              {info ? info : ''} {authContext.isTeacher ? (teacher ? teacher : '') : (student ? student : '')}
            </Typography>
            {sub
              ?
                <Typography
                  textAlign='center'
                  sx={{
                    mt:2
                  }}
                >
                  {sub}
                </Typography>
              :
                null
            }
            <Typography
              textAlign='center'
              sx={{
                fontStyle:'italic',
                mt:2
              }}
            >
              {details}
            </Typography>
          </Grid>
        </Grid>
      </Dialog>
    </>
    }
    </> 
  )
}

export default InfoModalTemplate;
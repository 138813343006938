import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { CircularProgress, FormControlLabel, Grid, Switch, Typography } from '@mui/material';
import { useAuth } from '../../context/authContext';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { ButtonCust } from '../ui/Buttons';
import { gradientCustom } from '../../lib/Styles';
import { useTheme } from '@emotion/react';


const ModalLogin = (props) => {
  const { title, content, setAuthenticated, bgColor, open, handleClose } = props;

  const [loginEmail, setLoginEmail] = useState();
  const [loginPassword, setLoginPassword] = useState();
  const [reset, setReset] = useState();
  const [user, setUser] = useState({});

  const auth = getAuth();
  const theme = useTheme();
  const navigate = useNavigate();

  // START LOGIN FUNCTION
  const authContext = useAuth();

  useEffect(() => {
    if (authContext.account) {
      navigate('/dashboard');
      handleClose();
    }

  }, [authContext.account])

  const loginUser = async () => {
    try {
      authContext.loginUser(loginEmail, loginPassword);
      authContext.getAccount();
      setLoginEmail();
      setLoginPassword();
      
    } catch (error) {
      console.log('Error logging in user');
    }
  }

  const recoverPassword = () => {
    try {
      return sendPasswordResetEmail(auth, loginEmail);
    } catch (error) {
      console.log('Error resetting password');
    }
  }


  const handlePasswordReset = () => {
    setReset(!reset);
  }

  const studentLogin = (student, password) => {
    setLoginEmail(student);
    setLoginPassword(password);
  }

  const LoginButton = (props) => {
    const { email, name, type } = props;

    return (
      <Button
        onClick={() => studentLogin(email, 'opine123')}
        color={type === "student" ? 'primary' : 'secondary'}
        sx={{
          px:1,
          py:.5,
          fontSize:12
        }}
      >
        {name}
      </Button>
    )
  }

  const LoginRealButton = (props) => {
    const { email, name, type } = props;

    return (
      <Button
        onClick={() => studentLogin(email, 'opineschool')}
        color={type === "student" ? 'primary' : 'secondary'}
        sx={{
          px:1,
          py:.5,
          fontSize:12
        }}
      >
        {name}
      </Button>
    )
  }

  // !!COPY INTO PARENT COMPONENT!!
  // const [open, setOpen] = useState(false);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      BackdropProps={{
        sx:{
          background: 
            gradientCustom({
              theme:theme, 
              type1:'light',
              type2:' ',
              color1:'nine',
              color2:'eight',
              deg:'135deg',
              opacity:'f8'
            })
        }
      }}
      PaperProps={{
        style: {
          backgroundColor: bgColor,
          boxShadow:'none'
        }
      }}
    >
      <DialogTitle textAlign="center">{title}</DialogTitle>
      <DialogContent>
        {/* <Typography textAlign="center">Enter your current login credentials to verify your account</Typography> */}
        <Grid
          container
          sx={{
            justifyContent:'center',
            // pt:20
          }}
          xs={12}
        >
          <Grid
            container
            justifyContent="center"
            sx={{
              gap:1,
              p:3,
              // borderRadius:3,
              // boxShadow:'0 0 10px #00000033',
              // backgroundColor:'light.main'
            }}
            xs={12}
            // sm={6}
            // md={3}
          >
            <Grid
              item
              xs={12}
            >
              <Grid
                container
                justifyContent="space-between"mb={1}
              >
                <Typography sx={{fontSize:12, fontWeight:600, backgroundColor:'#444', color:'#fff', px:1}}>TEACHERS</Typography>
                <Grid
                  container
                  sx={{
                    width:'100%',
                    border:'1px solid #444',
                    p:1,
                    mb:1
                  }}
                >
                  <LoginButton type="teacher" email="Luella.Little@opineschool.com" name="Luella" /> 
                  <LoginButton type="teacher" email="Harmony.Chen@opineschool.com" name="Harmony" /> 
                  <LoginButton type="teacher" email="Darrel.Acosta@opineschool.com" name="Darrel" /> 
                  <LoginButton type="teacher" email="Dominik.Leal@opineschool.com" name="Dominik" /> 
                </Grid>

                <Typography sx={{fontSize:12, fontWeight:600, backgroundColor:'#444', color:'#fff', px:1}}>STUDENTS</Typography>
                <Grid
                  container
                  sx={{
                    width:'100%',
                    border:'1px solid #444',
                    p:1,
                    mb:1
                  }}
                >
                  <LoginButton type="student" email="Nansi.Fry@opineschool.com" name="Nansi" /> 
                  <LoginButton type="student" email="Billy.Benitez@opineschool.com" name="Billy" /> 
                  <LoginButton type="student" email="Irving.Anthony@opineschool.com" name="Irving" /> 
                  <LoginButton type="student" email="Tara.Cox@opineschool.com" name="Tara" /> 
                  <LoginButton type="student" email="Nicolle.Mueller@opineschool.com" name="Nicolle" /> 
                  <LoginButton type="student" email="Jax.Sexton@opineschool.com" name="Jax" /> 
                </Grid>

                <Typography sx={{fontSize:12, fontWeight:600, backgroundColor:'#444', color:'#fff', px:1}}>DEVELOPERS</Typography>
                <Grid
                  container
                  sx={{
                    width:'100%',
                    border:'1px solid #444',
                    p:1,
                    mb:1
                  }}
                >
                  <LoginRealButton type="student" email="derrak.richard@test.edu" name="Derrak" /> 
                  <LoginRealButton type="student" email="frank.timmons@test.edu" name="Frank" /> 
                  <LoginRealButton type="student" email="hans.schroeder@test.edu" name="Hans" /> 
                  <LoginRealButton type="student" email="joe.roaden@test.edu" name="Joe R" /> 
                  <LoginRealButton type="student" email="joe.jack@test.edu" name="Joe J" /> 
                  <LoginRealButton type="student" email="kyle.miller@test.edu" name="Kyle" /> 
                  <LoginRealButton type="student" email="max.alvord@test.edu" name="Max" /> 
                  <LoginRealButton type="student" email="rosie.gardner@test.edu" name="Rosie" /> 
                  <LoginRealButton type="student" email="tyler.emmerson@test.edu" name="Tyler" /> 
                  <LoginRealButton type="student" email="lee.grambush@test.edu" name="Lee" /> 
                </Grid>

                {/* <Button onClick={() => studentLogin('student1@opine.com', 'testpass')} mb={2}>Nansi</Button>
                <Button onClick={() => studentLogin('student2@opine.com', 'testpass')} mb={2}>Student 2</Button>
                <Button color="secondary" onClick={() => studentLogin('teacher1@opine.com', 'testpass')} mb={2}>Teacher 1</Button>
                <Button color="secondary" onClick={() => studentLogin('teacher2@opine.com', 'testpass')} mb={2}>Teacher 2</Button> */}
              </Grid>
              <TextField
                label="Email"
                autoFocus
                InputLabelProps={{ shrink: true }}
                fullWidth
                value={loginEmail}
                onChange={(e) => setLoginEmail(e.target.value)}
              />
            </Grid>
            {!reset ? (
              <Grid
                item
                xs={12}
              >
                <TextField
                  label="Password"
                  type="password"
                  fullWidth
                  sx={{
                    mt:1
                  }}
                  InputLabelProps={{ shrink: true }}
                  value={loginPassword}
                  onChange={(e) => setLoginPassword(e.target.value)}
                />
              </Grid>

            ) : (
              <Typography>Enter your email and we will send you a recovery email if your email has an account at Opine.</Typography>
            )}
            <Grid
              item
              xs={12}
            >
              <ButtonCust
                onClick={reset ? recoverPassword : loginUser}
                buttonProps={{
                  fullWidth:true
                }}
                variant="contained"
                color1Start="primary.main"
                color1End="primary.main"
                color2Start="primary.dark"
                color2End="primary.main"
                angle="135deg"
                sx={{
                  color:'#fff',
                  mt:2
                }}
              >
                {reset ? 'Reset password' : 'Login'}
              </ButtonCust>

              {/* <Button variant="contained" color="primary" fullWidth sx={{p:2}} onClick={reset ? recoverPassword : loginUser}>
                {reset ? 'Reset password' : 'Login'}
              </Button> */}

              {/* <Typography>{authContext.user?.email}</Typography> */}
            </Grid>
            <FormControlLabel
              value="end"
              control={<Switch checked={reset} onChange={handlePasswordReset} color="primary" />}
              label="I forget my password"
              labelPlacement="end"
            />
              {/* <Button variant="standard" color="primary" sx={{color:'text.light'}} onClick={handlePasswordReset}>
                I forget my password
              </Button> */}
          </Grid>
        </Grid>
      </DialogContent>
      {/* <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleClose}>Subscribe</Button>
      </DialogActions> */}
    </Dialog>
  );
}

export default ModalLogin

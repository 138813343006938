import React, { useEffect, useState } from 'react'
import { addDoc, collection, collectionGroup, onSnapshot, orderBy, query, serverTimestamp, where } from 'firebase/firestore';
import { db } from '../../firebase';
import { Button, Checkbox, Dialog, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import { useAuth } from '../../context/authContext'
import SectionTemplate from './SectionTemplate';
import ModalRegister from '../auth/ModalRegister';
import { AutoGraph, Close, GraphicEq } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import MiniSectionTemplate from './MiniSectionTemplate';
import { timeDifference } from '../../lib/Functions';
import { fieldFormat } from '../../lib/Styles';
import { Logo } from '../../assets/logos'
import FeatureList from './FeatureList';
import MainWrapper from '../templates/MainWrapper';
import QuizFeatureList from './QuizFeatureList';


const Landing = () => {
  const theme = useTheme();
  const authContext = useAuth();

  const [betaReceived, setBetaReceived] = useState(false);

  const [openBeta, setOpenBeta] = useState(false);
  const handleOpenBeta = () => {
    setOpenBeta(true);
  }
  const handleCloseBeta = () => {
    setOpenBeta(false);
    setBetaReceived(false);
  }


  // LOGIN AND REGISTER FUNCTIONS
  const [open, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState();
  const [authenticated, setAuthenticated] = useState(false);

  const handleClickOpen = (cont) => {
    handleOpenBeta();
    // setModalContent(cont)
    // setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    handleClose();
  }, [authenticated])

  return (
    <>
      <Grid
        className="Funky 1"
        container
        sx={{
          justifyContent:'center',
          backgroundColor:'background.main',
          pb:12
        }}
      >
        {/* HEADER */}
        <SectionTemplate
          align="flex-start"
          content={{
            headline: 'Civil discourse in schools all across the country',
            body: 'Give students the ability to debate in a safe and exciting method that requires supporting statements with researched facts and studies.',
            cta: 'Get Early Access'
          }}
          theme="light"
          gradient1={theme.palette.brandDark.eight}
          gradient2={theme.palette.brandDark.eight}
          title="sectionTop"
          anchor="top"
          imgLight="https://firebasestorage.googleapis.com/v0/b/opine-production.appspot.com/o/static%2Fimg%2FScene_001_A0002.png?alt=media&token=f1224eaf-f133-4648-a5d1-9a42ae6af737" //"https://images.unsplash.com/photo-1570616969692-54d6ba3d0397?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2022&q=80"
          imgDark="https://images.unsplash.com/photo-1570616969692-54d6ba3d0397?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2022&q=80"
          imgFigure="images/Diorama_Diorama_02_v01a_figure.png"
          handleClickOpen={handleClickOpen}
          survey={false}
        />

        <MainWrapper>
          <FeatureList handleClickOpen={handleClickOpen} />
          <QuizFeatureList handleClickOpen={handleClickOpen} />
        </MainWrapper>
          
      </Grid>


      <ModalRegister
        title="Register"
        bgColor="white"
        open={open}
        handleClose={handleClose}
        setAuthenticated={setAuthenticated}
      />

      <Dialog
        open={openBeta}
        onClose={handleCloseBeta}
      >
        {
          betaReceived
            ?
              <Grid
                container
                sx={{
                  justifyContent:'center',
                  px:4,
                  pb:4
                }}
              >
                <IconButton
                  onClick={handleCloseBeta}
                  variant="contained"
                >
                  <Close sx={{fontSize:48}} />
                </IconButton>
                <Typography
                  variant="h3"
                  textAlign="center"
                >
                  Your request to join the Opine beta has been received!
                </Typography>
                <Typography
                  variant="h4"
                  textAlign="center"
                  mt={2}
                  lineHeight={1.3}
                >
                  We appreciate your interest in the Opine platform and we will be in touch soon.
                </Typography>
              </Grid>

            :
              <BetaForm setBetaReceived={setBetaReceived} />
        }
      </Dialog>
        

    
    </>
  )
}

export default Landing

const BetaForm = (props) => {
  const { setBetaReceived } = props;
  const [email, setEmail] = useState(null);
  const [name, setName] = useState(null);
  const [district, setDistrict] = useState(null);
  const [school, setSchool] = useState(null);
  const [interest, setInterest] = useState({opine:true, quiz:true})

  const payload = {
    email:email,
    name:name,
    district:district,
    school:school,
    interest_opine:interest.opine,
    interest_quiz:interest.quiz,
    createdAt:serverTimestamp()
  }
  
  const theme = useTheme();

  // SAVE BETA REQUEST TO DB
  const requestBeta = async () => {
    try {
      const docRef = collection(db, 'betaRequests');
      const payload = {
        email:email,
        name:name,
        district:district,
        school:school,
        interest_opine:interest.opine,
        interest_quiz:interest.quiz,
        createdAt:serverTimestamp()
      }
      await addDoc(docRef, payload);
      setBetaReceived(true);

    } catch (error) {
      console.log('Error adding user to beta', error)
    }
  }



  return (
    <Grid
      container
      sx={{
        justifyContent:'center',
        rowGap:3,
        p:4
      }}
    >
      <Grid
        container
        alignItems="flex-end"
        justifyContent="center"
      >
        <Logo
          mt={7}
          height={30}
          color1={theme.palette.brand.five}
        />
        <Typography
          sx={{
            fontWeight:600,
            lineHeight:1,
            color:theme.palette.brand.eight
          }}
        >
          beta
        </Typography>
      </Grid>
      <TextField
        fullWidth
        // sx={fieldFormat.standard}
        name="name"
        label="Name"
        placeholder="Enter your name"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />

      <TextField
        fullWidth
        // sx={fieldFormat.standard}
        name="email"
        label="Email"
        placeholder="Enter your email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />

      <TextField
        fullWidth
        // sx={fieldFormat.standard}
        name="district"
        label="School District"
        placeholder="Enter your school district"
        value={district}
        onChange={(e) => setDistrict(e.target.value)}
      />

      <TextField
        fullWidth
        // sx={fieldFormat.standard}
        name="school"
        label="School Name"
        placeholder="Enter your school name"
        value={school}
        onChange={(e) => setSchool(e.target.value)}
      />

      <Grid
        container
      >
        <Grid
          item
          xs
        >
          <Typography>
            What are you interested in?
          </Typography>
        </Grid>
        <Grid
          item
          xs="auto"
        >
          <FormGroup aria-label="position" row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={interest.opine}
                  onChange={() => setInterest({...interest, opine:!interest.opine})}
                  sx={{
                    transform:'scale(1.3)'
                  }}
                />
              }
              label="Opine"
              labelPlacement="top"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={interest.quiz}
                  onChange={() => setInterest({...interest, quiz:!interest.quiz})}
                  sx={{
                    transform:'scale(1.3)'
                  }}
                />
              }
              label="Opine Quiz"
              labelPlacement="top"
            />
          </FormGroup>
        </Grid>
      </Grid>

      <Grid
        container
        sx={{
          justifyContent:'center',
        }}
      >
        <Button
          disabled={!interest.quiz && !interest.opine}
          onClick={requestBeta}
          variant="contained"
          sx={{
            fontSize:24,
            py:2,
            px:4
          }}
        >
          {!interest.quiz && !interest.opine ? 'Select which platform(s) you want access to' : 'Request early access' }
        </Button>
        <Typography mt={1}>
          *Opine beta access is offered in limited quantities
        </Typography>
      </Grid>

    </Grid>
  )
}
import React, { useEffect, useState } from 'react'
import {db} from '../firebase';
import { collection, doc, getDocs, onSnapshot, query, orderBy, limit, setDoc, startAfter } from '@firebase/firestore';
import { useTheme } from '@emotion/react'
import { Grid, useMediaQuery } from '@mui/material'
import { Helmet } from 'react-helmet-async'
import MainFooter from '../components/nav/MainFooter'
import MainNavbar from '../components/nav/MainNavbar'
import PageTemplate from './PageTemplate'
import OpinionsBrowse from '../components/browse/OpinionsBrowse';
import Landing from '../components/landing/Landing';

const LandingPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'), {defaultMatches: true})

  return (
    <>
      <Helmet>
        <title>Home | Opine</title>
      </Helmet>
      <MainNavbar />
      <Grid container xs={12}>
        {/* <MainNavbar /> */}
        <Grid
          container
          justifyContent="center"
          // minHeight="calc(100vh - 80px)"
          minHeight="100vh"
          my={isMobile ? 0 : 0}
          // mb={20}
          py={0}
          xs={12}
        >
          <Landing />
        </Grid>
        {/* <MainFooter /> */}
      </Grid>
    </>
  )
}

export default LandingPage

import React, { useState } from 'react';
import { signOut } from 'firebase/auth';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { useAuth } from '../../context/authContext';
import { AdminPanelSettings, Business, Dashboard, Grade, Home, Person } from '@mui/icons-material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { auth } from '../../firebase';

const ProfileMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const authContext = useAuth();
  const location = useLocation();
  const navigate = useNavigate();


  function locationCheck() {
    if (location.pathname.substring(0,10) == '/dashboard') {
       navigate('/dashboard');
    }
  };


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // START LOGOUT FUNCTION
  const logoutUser = async () => {
    try {
      authContext.logoutUser();
      authContext.getAccount();
      navigate('/');

    } catch (error) {
      console.log('Error logging user out', error);
    }
  }
  // END LOGOUT FUNCTION
  
  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            {authContext.user.photoURL ? (
              <img src={authContext.user.photoURL} style={{width:40, height:40, objectFit:'cover', borderRadius:200}} alt="" />
            ) : (
              <Avatar sx={{ backgroundColor:'primary.main', width: 40, height: 40 }} />
            )}
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem component={Link} to="/dashboard">          
          <ListItemIcon>
            <Dashboard /> 
          </ListItemIcon>
          Dashboard
        </MenuItem>
        {authContext.isTeacher ? (
          <MenuItem component={Link} to="/grading">          
            <ListItemIcon>
              <Grade /> 
            </ListItemIcon>
            Grade
          </MenuItem>
        ):null}
        {/* <MenuItem component={Link} to="/notifications">          
          <ListItemIcon>
            <Dashboard /> 
          </ListItemIcon>
          Notifications
        </MenuItem> */}
        <MenuItem component={Link} to="/messages">          
          <ListItemIcon>
            <Person /> 
          </ListItemIcon>
           Messages
        </MenuItem>
        <Divider />
        <MenuItem onClick={logoutUser}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
}

export default ProfileMenu;
import React, { useState, useEffect } from 'react'
import { AppBar, Box, Button, Container, CssBaseline, Grid, Slide, Toolbar, Tooltip, Typography, useMediaQuery, useScrollTrigger } from '@mui/material'
import { useTheme } from '@emotion/react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '../../context/authContext'
import ProfileMenu from '../profile/ProfileMenu'
import ModalLogin from '../auth/ModalLogin'
import ModalRegister from '../auth/ModalRegister'
import { Logo, Logotype } from '../../assets/logos'
import { ClassRounded, CollectionsBookmarkRounded, Home, HomeRounded } from '@mui/icons-material'
import { hashScroll } from '../../lib/Functions'

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

// HideOnScroll.propTypes = {
//   children: PropTypes.element.isRequired,
//   /**
//    * Injected by the documentation to work in an iframe.
//    * You won't need it on your project.
//    */
//   window: PropTypes.func,
// };


const MainNavbar = (props) => {
  const { fixed } = props;
  const authContext = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {defaultMatches: true})
  const [open, setOpen] = useState(false);
  const [isFixed, setIsFixed] = useState(fixed != undefined ? fixed : true);
  const [isDashboard, setIsDashboard] = useState();
  const [isAdmin, setIsAdmin] = useState();
  const [isHome, setIsHome] = useState();
  const [modalContent, setModalContent] = useState();
  const [authenticated, setAuthenticated] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const currentEnv = process.env.REACT_APP_CURRENT_ENV

  console.log('window', window.location.hash)

  const locationCheck = () => {
    if (location.pathname.substring(0,10) == '/dashboard') {
      setIsDashboard(true);
    } else if (location.pathname.substring(0,6) == '/admin') {
      setIsAdmin(true);
    } else if (location.pathname.substring(0,3) == '/s/' || location.pathname == '/') {
      setIsHome(true);
    } else {
      setIsDashboard(false);
      setIsAdmin(false);
    }
  }

  useEffect(() => {
    locationCheck();
  }, [location])

  // Scroll to hash if it comes from a linked navigation url
  useEffect(() => {
    if (location) {
      console.log('linked running', location)
      hashScroll(location.hash.replace('#',''));
    }
  }, [location])

  const handleClickOpen = (cont) => {
    setModalContent(cont)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    handleClose();
  }, [authenticated])

  const MenuItem = ({ children, link, tooltip, navLocation }) => (
    <Grid
      item
      className="menu item"
      sx={{
        mx:{xs:0, sm:1, md:2}
      }}
      xs={4}
      md
    >
      {/* <Tooltip
        title={<Typography>{tooltip}</Typography>}
        arrow
        PopperProps={{
          sx: {
            "& .MuiTooltip-tooltip": {
              backgroundColor: 'primary.main',
              px:3,
              py:1
            },
            "& .MuiTooltip-arrow": {
              color: 'primary.main',
            }
          }
        }}                      
      > */}
        <Link
          to={link.to}
          state={link.state}
          style={{
            textDecoration:'none'
          }}
          // onClick={setBottomNav(tooltip)}
        >
          <Grid
            container
            direction="column"
            sx={{
              justifyContent:'center',
              alignItems:'center'
            }}>
          {children}
          {/* {navLocation == tooltip.toLowerCase() ? <Typography variant="accent" sx={{fontWeight:600, fontSize:12, letterSpacing:.5, color:'majestic.main'}}>{tooltip}</Typography> : null} */}
          </Grid>
        </Link>
      {/* </Tooltip> */}
    </Grid>
  
  )
  


  return (
    <>
      <HideOnScroll {...props}>
        <AppBar sx={{background:'#fff', mt:-1}} >
          <Toolbar p={0} sx={{pl:0}}>
            <Grid
              container
              sx={{
                // top: 0,
                alignItems:'center'
              }}
              xs={12}
            >
              <Grid
                container
                sx={{
                  top:0,
                  alignItems:'center',
                  justifyContent:'center',
                  zIndex:100
                }}
                xs={12}
              >
                <Grid container sx={{alignItems:'center', justifyContent:'space-between'}} xs={10}>
                  <Grid item>
                    <Link to="/" style={{padding:'0px'}}>
                      <Logo
                        mt={7}
                        height={isMobile ? '25px' : '30px'}
                        color1={theme.palette.brand.eight}
                      />
                    </Link>
                  </Grid>

                  <Grid item>
                  {/* MENU ICONS */}
                  {
                    authContext.user
                      ? 
                        !isMobile
                          ? 
                            <Grid item>
                              <Box display="flex" alignItems="center">
                              <MenuItem
                                  tooltip="Home"
                                  link={{
                                    to:'/dashboard',
                                    state:{ newValue: 0 }              
                                  }}
                                >
                                  <HomeRounded
                                    sx={{
                                      fontSize:36,
                                      color:'primary.main',
                                      transition:'.15s',
                                      '&&:hover':{
                                        // fontSize:40
                                        color:'primary.dark'
                                      }
                                    }}
                                  />
                                </MenuItem>
                                <MenuItem
                                  tooltip="Classes"
                                  link={{
                                    to:'/classes',
                                    state:{ newValue: 0 }              
                                  }}
                                >
                                  <CollectionsBookmarkRounded
                                    sx={{
                                      fontSize:29,
                                      color:'primary.main',
                                      transition:'.15s',
                                      '&&:hover':{
                                        // fontSize:40
                                        color:'primary.dark'
                                      }
                                    }}
                                  />
                                </MenuItem>
                              </Box>
                            </Grid>
                          :
                            null
                      
                      : 
                        <Grid item>
                          <Button
                            color="secondary"
                            sx={{
                              fontWeight:700
                            }}
                            onClick={() => {
                              if (window.location.pathname.length > 1) {
                                navigate('/#features');
                              }
                              hashScroll('features')
                            }}
                          >
                            Features
                          </Button>
                          <Button
                            color="secondary"
                            sx={{
                              fontWeight:700
                            }}
                            onClick={() => {
                              if (window.location.pathname.length > 1) {
                                navigate('/#quiz');
                              }
                              hashScroll('quiz')
                            }}
                          >
                            Quiz
                          </Button>
                        </Grid>


                  }

                  </Grid>

                  <Grid item>
                    <Box display="flex">
                      {authContext.user ? (
                        <Grid container alignItems="center" alignContent="center">
                          <Box>
                            <Typography variant="subtitle2" sx={{color:'green.dark', lineHeight:1, textAlign:'right', fontWeight:600}}>{authContext.account?.firstName ? authContext.account.firstName : authContext.account?.displayName}</Typography>
                            <Typography variant="subtitle1" sx={{color:'green.dark', lineHeight:1, textAlign:'right', fontSize:13, fontWeight:500}}>{authContext.account?.firstName ? authContext.account.displayName : null}</Typography>
                          </Box>
                          <ProfileMenu />
                        </Grid>
                      ) : (
                        currentEnv == "STAGING"
                          ?
                            <Grid container>
                              <Button
                                // href="/register"
                                onClick={() => handleClickOpen('register')}
                              >
                                REGISTER
                              </Button>
                              <Button
                                // href="/login"
                                onClick={() => handleClickOpen('login')}
                              >
                                LOGIN
                              </Button>
                            </Grid>
                          :
                            <Grid container>
                              {/* <Button
                                href="/register"
                                // onClick={() => handleClickOpen('register')}
                              >
                                REGISTER
                              </Button> */}
                              {
                                isMobile
                                  ?
                                    null
                                  :
                                    <Button
                                      href="/login"
                                      // onClick={() => handleClickOpen('login')}
                                    >
                                      LOGIN
                                    </Button>
                              }
                            </Grid>

                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              {modalContent === 'login' ? (
                <ModalLogin
                  title="Login"
                  bgColor="white"
                  open={open}
                  handleClose={handleClose}
                  setAuthenticated={setAuthenticated}
                />
              ) : (
                <ModalRegister
                  title="Register"
                  bgColor="white"
                  open={open}
                  handleClose={handleClose}
                  setAuthenticated={setAuthenticated}
                />
              )}
            </Grid>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
    </>
  )
}

export default MainNavbar

import { Grid } from '@mui/material';
import React from 'react'
import { Helmet } from 'react-helmet-async';
import MainFooter from '../components/nav/MainFooter';
import MainNavbar from '../components/nav/MainNavbar';

const PageTemplate = (props) => {
  const { tp, title, content, bckgrnd } = props;

  return (
    <>
      <Helmet>
        <title>{`${title}`} | Opine</title>
      </Helmet>
      <MainNavbar />
      <Grid
        className="PageTemplate Grid Top"
        container
        sx={{
          minHeight: 'calc(100vh - 80px)',
          backgroundColor: bckgrnd ? bckgrnd : 'white',
          justifyContent:'center',
          alignContent: 'space-between'
        }}
      >
        <Grid
          className="PageTemplate Grid item container"
          item
          container
          sx={{
            justifyContent:'center',
            alignContent:'flex-start',
            mt: tp ? tp : 8
          }}
          xs={12}
        >
          {content}        
        </Grid>
      </Grid>
      <MainFooter />
    </>
  )
}

export default PageTemplate

import React from 'react'
import NotFound from '../components/NotFound'
import PageTemplate from './PageTemplate'

const NotFoundPage = () => {
  return (
    <>
      <PageTemplate title="Not Found" tp={.0001} content={<NotFound />} />
    </>
  )
}

export default NotFoundPage
